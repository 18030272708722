import React, { useState } from 'react';
import style from './styles.module.scss';
import { Checkbox } from '..';
import { icons } from '../../assets/png';
import { Arrow, Box, DrugArrow, Pen } from '../../assets/svg';
import clsx from 'clsx';
import moment from 'moment';
import { BUCKET } from '../../constants/api';
import TableItemEdit from '../CategoryItemEdit';
import PostItemEdit from '../PostItemEdit';

const TableItem = ({
  updateTable,
  tableColumn,
  isUserTable,
  isCategoryTable,
  item,
  removeItem,
  onChangeOrder,
  index,
  itemsLength
}) => {
  let date = item.createdAt
    ? moment
        .utc(item.publishDate ? new Date(item.publishDate) : item.createdAt)
        .format('DD/MM/YYYY')
    : moment().format('DD/MM/YYYY');

  const [showEdit, setShowEdit] = useState(false);

  const [checkbox, setCheckBox] = useState();

  return (
    <div>
      <div className={clsx(style.tableItem, { [style.underline]: isUserTable })}>
        {tableColumn.map((row) => {
          if (row.type === 'checkbox') {
            return (
              <div
                key={row.type}
                className={style.tableItem__checkbox}
                style={{ width: row.width }}
              >
                <Checkbox value={checkbox} onClick={() => setCheckBox(!checkbox)} />
              </div>
            );
          }
          if (row.type === 'arrows') {
            return (
              <div key={row.type} className={style.tableItem__arrows} style={{ width: row.width }}>
                <div
                  className={clsx(style.tableItem__arrowsTop, {
                    [style.cursorStyle]: index > 0
                  })}
                  onClick={() => index > 0 && onChangeOrder(item._id, true)}
                >
                  <DrugArrow color={index === 0 && '#bebbbf'} />
                </div>

                <div
                  className={clsx(style.tableItem__arrowsBottom, {
                    [style.cursorStyle]: index < itemsLength - 1
                  })}
                  onClick={() => index < itemsLength - 1 && onChangeOrder(item._id, false)}
                >
                  <DrugArrow color={index === itemsLength - 1 && '#bebbbf'} />
                </div>
              </div>
            );
          }
          if (row.type === 'editOrDelete') {
            return (
              <div
                key={row.type}
                className={style.tableItem__editBlock}
                style={{ width: row.width }}
              >
                <div className={style.tableItem__pen} onClick={() => setShowEdit(!showEdit)}>
                  <Pen />
                </div>
                <div className={style.tableItem__box} onClick={() => removeItem(item._id)}>
                  <Box />
                </div>
              </div>
            );
          }
          if (row.type === 'type') {
            return (
              <div key={row.type} className={style.tableItem__type} style={{ width: row.width }}>
                <div
                  className={clsx(style.tableItem__typeSquare, {
                    [style.story]: item[row.type] === 'Story'
                  })}
                />

                <span>{item[row.type]}</span>
              </div>
            );
          } else if (row.type === 'product') {
            return (
              <div key={row.type} className={style.tableItem__test} style={{ width: row.width }}>
                {item.cover ? (
                  <img
                    src={`${BUCKET}/${item.cover}`}
                    className={style.tableItem__cover}
                    alt="Default icon"
                  />
                ) : (
                  <img src={icons.defaultItemIcon} alt="Default icon" />
                )}
              </div>
            );
          } else if (row.type === 'date') {
            return (
              <div
                key={row.type}
                style={{ width: row.width }}
                className={clsx(style.tableItem__date, style.tableItem__row)}
              >
                {date}
              </div>
            );
          } else if (row.type === 'status') {
            return (
              <div
                key={row.type}
                style={{ width: row.width }}
                className={clsx(style.tableItem__status, {
                  [style.active]: item.status
                })}
              >
                {item.statusType}
              </div>
            );
          } else if (row.type === 'title') {
            return (
              <div
                key={row.type}
                style={{ width: row.width }}
                // className={style.tableItem__titleBlock}
              >
                <div className={style.tableItem__title}>{item[row.type]}</div>
                {item.subtitle && <div className={style.tableItem__subtitle}>{item.subtitle}</div>}
                <div className={style.tableItem__openText} onClick={() => setShowEdit(!showEdit)}>
                  View all information <Arrow />
                </div>
              </div>
            );
          } else {
            return (
              <div key={row.type} style={{ width: row.width }}>
                {item[row.type]}
              </div>
            );
          }
        })}
      </div>
      {showEdit &&
        (isCategoryTable ? (
          <TableItemEdit updateTable={updateTable} item={item} />
        ) : (
          <PostItemEdit updateTable={updateTable} item={item} />
        ))}
    </div>
  );
};

export default TableItem;
