import { CLEAR_CATEGORIES, SET_CATEGORIES } from '../actionTypes/categories';
import { fetchRequest } from '../../API/fetchRequest';

export const setCategories = (payload) => ({
  type: SET_CATEGORIES,
  payload
});

export const getCategories = (data) => async (dispatch) => {
  const data = {
    page: 'Main',
    title: '',
    main: true,
    default: true,
    days: null
  };
  const resp = await fetchRequest('POST', 'admin/categories', data, true);
  if (resp.success) {
    dispatch(setCategories(resp.default));
  }
};

export const clearCategories = () => ({
  type: CLEAR_CATEGORIES
});
