import { createAsyncThunk } from '@reduxjs/toolkit';
import { instance } from '../../../utils/axios';

export const createPromptThunks = createAsyncThunk(
  'prompt/create-prompt',
  async ({ message, publishDate }, { rejectWithValue }) => {
    try {
      const prompt = await instance.post('/prompt/create-prompt', {
        message,
        publishDate
      });
      return prompt.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const lazyLoadPromptsThunks = createAsyncThunk(
  'prompt/load-prompts',
  async ({ page }, { rejectWithValue }) => {
    try {
      const prompt = await instance.get(`/prompt/get-prompts?page=${page}`);
      return prompt.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updatePromptThunks = createAsyncThunk(
  'prompt/update-prompt',
  async ({ message, publishDate, promptId }, { rejectWithValue }) => {
    try {
      const prompt = await instance.patch(`/prompt/update-prompt/${promptId}`, {
        message,
        publishDate
      });
      return prompt.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createSearchRequestThunks = createAsyncThunk(
  'prompt/find-messages',
  async ({ phrase, page = 1 }, { rejectWithValue }) => {
    try {
      const searchRequest = await instance.get(
        `/prompt/find-messages?searchPhrase=${phrase}&page=${page}`
      );
      return searchRequest.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deletePromptThunks = createAsyncThunk(
  'prompt/delete-prompt',
  async ({ promptId }, { rejectWithValue }) => {
    try {
      await instance.delete(`/prompt/delete-prompt/${promptId}`);
      return {
        promptId
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deletePromptsThunks = createAsyncThunk(
  'prompt/delete-prompts',
  async ({ promptIds }, { rejectWithValue }) => {
    try {
      await instance.delete('/prompt/delete-prompts', {
        data: { promptIds }
      });
      return {
        promptIds
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPromptsThunks = createAsyncThunk(
  'prompt/get-prompts',
  async ({ page }, { rejectWithValue }) => {
    try {
      const localTime = new Date(Date.now());

      const prompt = await instance.get(`/prompt/get-prompts?page=${page}&time=${localTime}`);
      return prompt.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPromptThunks = createAsyncThunk(
  'prompt/get-prompt',
  async (_, { rejectWithValue }) => {
    try {
      const localTime = new Date(Date.now());

      const prompt = await instance.get(`/prompt/get-prompt-admin?time=${localTime}`);
      return {
        prompt: prompt.data
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMessagesThunks = createAsyncThunk(
  'prompt/get-messages',
  async ({ page }, { rejectWithValue }) => {
    try {
      const localTime = new Date(Date.now());
      const messages = await instance.get(
        `/prompt/get-messages-admin?page=${page}&time=${localTime}`
      );
      return messages.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const lazyLoadMessagesThunks = createAsyncThunk(
  'prompt/load-messages',
  async ({ page }, { rejectWithValue }) => {
    try {
      const messages = await instance.get(`/prompt/get-messages-admin?page=${page}`);
      return messages.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteMessageThunks = createAsyncThunk(
  'prompt/delete-message',
  async ({ messageId }, { rejectWithValue }) => {
    try {
      await instance.delete(`/prompt/delete-message/${messageId}`);
      return {
        messageId
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
