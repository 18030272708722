import React, { useRef, useState } from 'react';
import style from './styles.module.scss';
import clsx from 'clsx';
import { Arrow } from '../../assets/svg';
import { useOutsideClick } from '../../hooks/useOutsideClick';

const Select = ({
  label,
  error,
  placeholder,
  data,
  onChange,
  formik,
  name,
  value = '',
  header = false,
  isDisabled = false
}) => {
  const [isOpen, setOpen] = useState(false);

  const popupRef = useRef();

  useOutsideClick({ ref: popupRef, callback: () => setOpen(false) });

  return (
    <div className={style.selectBlock}>
      <div className={clsx(style.selectBlock__label, { [style.focus]: isOpen })}>{label}</div>
      <div
        className={clsx(
          style.select,
          { [style.focus]: isOpen },
          { [style.error]: !!error },
          { [style.adaptive]: header }
        )}
        ref={popupRef}
        onClick={() => {
          !isDisabled && setOpen(!isOpen);
        }}
      >
        {!!placeholder && !formik.values[name] && (
          <div
            className={clsx(style.select__placeholder, {
              [style.error]: !!error
            })}
          >
            <p>{placeholder}</p>
          </div>
        )}

        <p>{formik ? (formik.values[name] ? formik.values[name] : '') : value}</p>

        {!isDisabled && (
          <div
            className={clsx(
              style.select__arrow,
              { [style.active]: isOpen },
              { [style.adaptive]: header }
            )}
          >
            <Arrow />
          </div>
        )}

        <div
          className={clsx(style.list__wrapper, { [style.active]: isOpen })}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={clsx(style.list)}>
            {/* {!data &&
              defaultValues.map((item) => {
                return (
                  <div onClick={() => alert(1)} className={style.list__item}>
                    {item}
                  </div>
                );
              })} */}
            {!!data &&
              data.map((item) => {
                return (
                  <div
                    key={item}
                    onClick={() => {
                      onChange(item, name);
                      setOpen(false);
                    }}
                    className={style.list__item}
                  >
                    {item}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {!!error && <div className={style.selectBlock__error}>{error}</div>}
    </div>
  );
};

export default Select;
