import React, { useEffect } from "react";
import style from "./styles.module.scss";
import TableBody from "../../blocks/common/tableBody";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../redux/actions/users";
import { Button } from "../../components";
import { fetchRequest } from "../../API/fetchRequest";
const tableColumn = [
  { title: "Name", type: "name", width: "22.7%" },
  { title: "Email", type: "email", width: "24.5%" },
  { title: "Phone", type: "phone", width: "21.9%" },
  { title: "Account status", type: "status", width: "16%" },
  { title: "Registration date", type: "date", width: "14.7%" },
];

const UsersManagment = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.usersStore);

  // console.log("users", users);
  const downloadUsers = async () => {
    const resp = await fetchRequest("GET", "admin/userstable", undefined, true);
    downloadFile(resp.file);
  };

  const downloadFile = (file) => {
    let element = document.createElement("a");

    element.setAttribute(
      "href",
      `data:text/csv;base64,` + encodeURIComponent(file)
    );

    element.setAttribute("download", "Users table");
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  useEffect(() => {
    dispatch(getUsers());
    // eslint-disable-next-line
  }, []);
  return (
    <div className={style.usersManagment}>
      <div className={style.usersManagment__header}>
        <div className={style.usersManagment__headerTitle}>Users managment</div>
        <div className={style.usersManagment__headerButton}>
          <Button text="Download" onClick={downloadUsers} />
        </div>
      </div>
      <TableBody tableColumn={tableColumn} data={users} isUserTable={true} />
    </div>
  );
};

export default UsersManagment;
