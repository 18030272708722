import React from 'react';
import style from './styles.module.scss';
import clsx from 'clsx';

const AppButton = ({ text, onClick = () => null, className = '' }) => {
  return (
    <button className={clsx(style.button, className)} onClick={onClick}>
      <span>{text}</span>
    </button>
  );
};

export default AppButton;
