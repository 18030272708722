import React, { useState } from "react";
import style from "./styles.module.scss";
import moment from "moment";
import { useFormik } from "formik";
import { Modal } from "../../../components";
import {
  Input,
  Button,
  TextArea,
  DatePicker,
  TimePicker,
} from "../../../components";
import { NotificationShema } from "../../../utils/validations/NotificationShema";
import { fetchRequest } from "../../../API/fetchRequest";
const CentralBlock = ({ updateNotification }) => {
  const [showModal, setShowModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      date: new Date(),
      time: "",
      timeOfDay: "pm",
      title: "",
      description: "",
    },
    validationSchema: NotificationShema,
    onSubmit: async (values, { resetForm, setErrors }) => {
      let futureDate = getFullDate().fullDateInMoment;
      let currentDate = moment(new Date()).format("x");

      if (moment(futureDate).format("x") < currentDate) {
        setErrors({ time: "Enter a future date" });
        return;
      }
      setShowModal(true);
    },
  });

  const getFullDate = () => {
    let date = formik.values.date;
    let time = formik.values.time;
    let timeOfDay = formik.values.timeOfDay;
    let convDate = moment(date).format("MM/DD/YYYY");

    if (timeOfDay === "am") {
      convDate = convDate + ` ${time}`;
    } else {
      let timeParts = time.split(":");
      let hours = Number(timeParts[0]) + 12;

      convDate = convDate + ` ${hours}:${timeParts[1]}`;
    }

    let fullDateInMoment = moment(convDate, "MM/DD/YYYY hh:mm");

    let utcDate = new Date(fullDateInMoment).toISOString();

    let utcMoment = moment.utc(utcDate).format("MM/DD/YYYY HH:mm");

    return { convDate: utcMoment, fullDateInMoment };
  };

  // const validateData = () => {
  //   let futureDate = getFullDate().fullDateInMoment;
  //   let currentDate = moment(new Date()).format("x");

  //   if (moment(futureDate).format("x") < currentDate) {
  //     setErrors({ time: "Enter a future date" });
  //     return;
  //   }
  // };
  const onChange = (value, name) => {
    formik.setFieldValue(name, value);
  };

  const onSubmit = async () => {
    let futureDate = getFullDate().convDate;

    let data = {
      date: futureDate,
      title: formik.values.title,
      body: formik.values.description,
    };

    let resp = await fetchRequest("POST", "admin/notification", data, true);

    formik.resetForm();

    setShowModal(false);
  };

  return (
    <div className={style.centralBlock}>
      {showModal && (
        <Modal
          text="Are you sure you want to send this notification?"
          onClose={() => setShowModal(false)}
          onSubmit={onSubmit}
        />
      )}
      <div className={style.centralBlock__title}>Create Notification</div>
      <Input
        label="Notification title"
        value={formik.values.title}
        onChange={(val) => {
          onChange(val, "title");
        }}
        error={formik.touched.title && formik.errors.title}
      />
      <div className={style.centralBlock__field}>
        <TextArea
          formik={formik}
          label="Notification description"
          name="description"
          onChange={onChange}
          error={formik.touched.description && formik.errors.description}
        />
      </div>
      <div className={style.centralBlock__field}>
        <DatePicker
          value={formik.values.date}
          onChange={(val) => {
            onChange(val, "date");
          }}
          label="Date"
        />
      </div>
      <div className={style.centralBlock__field}>
        <TimePicker
          formik={formik}
          onChange={onChange}
          label="Time"
          timeName="timeOfDay"
          name="time"
          error={formik.touched.time && formik.errors.time}
        />
      </div>

      <div className={style.centralBlock__button}>
        <Button text="Confirm" onClick={() => formik.submitForm()} />
      </div>
    </div>
  );
};

export default CentralBlock;
