import defaultAvatar from './defaultAvatar.png';
import userAva from './userAva.png';
import defaultItemIcon from './defaultItemIcon.png';
import modalLogo from './modalLogo.png';
import appLogo from './appLogo.png';
import promptImage from './promptHeaderImage.png';

export const icons = {
  defaultAvatar,
  userAva,
  defaultItemIcon,
  modalLogo,
  appLogo,
  promptImage
};
