import React, { useRef, useState } from 'react';
import style from './styles.module.scss';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../../scss/calendar.scss';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import clsx from 'clsx';
import moment from 'moment';
import { CalendarIcon } from '../../assets/svg';

const DatePicker = ({ value, onChange, label, error, formik, name, isDisabled }) => {
  const [isOpen, setOpen] = useState(false);
  const popupRef = useRef();

  useOutsideClick({ ref: popupRef, callback: () => setOpen(false) });

  const date = moment(formik ? formik.values.date : value).format('DD/MM/YYYY');

  return (
    <div className={style.datePicker}>
      <div className={clsx(style.datePicker__label, { [style.focus]: isOpen })}>{label}</div>
      <div
        className={clsx(style.field, { [style.focus]: isOpen }, { [style.error]: !!error })}
        ref={popupRef}
        onClick={() => {
          if (isDisabled) return;
          setOpen(!isOpen);
        }}
      >
        {date}
        {!isDisabled && <CalendarIcon />}

        <div className={style.datePicker__calendar} onClick={(e) => e.stopPropagation()}>
          {isOpen && (
            <Calendar
              minDate={new Date()}
              onChange={(val) => {
                if (isDisabled) return;
                onChange(val, name);
                setOpen(false);
              }}
              value={value}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
