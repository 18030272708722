import { Route, Redirect } from "react-router-dom";
//interface
//utils
import PrivateNavigation from "./privateNavigation";
import { getToken } from "../utils/tokenLS";

const PrivateRoute = (props) => {
  const isAuth = getToken();
  return isAuth ? (
    <Route path="/" component={PrivateNavigation} />
  ) : (
    <Redirect to="/signIn" />
  );
};

export default PrivateRoute;
