import axios from 'axios';
import { getToken } from '../tokenLS';

const token = getToken();

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  }
});
