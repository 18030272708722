import React, { useRef } from "react";
import styles from "./styles.module.scss";

import { MantrasList } from "./MantrasList";
import { AddMantra } from "./AddMantra";

const Mantras = () => {
  const mantraListRef = useRef();

  const handleAddMantra = (created) => {
    debugger;
    mantraListRef.current.addMantra(created);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_left}>
        <MantrasList ref={mantraListRef} />
      </div>

      <div className={styles.wrapper_right}>
        <AddMantra onAddMantra={handleAddMantra} />
      </div>
    </div>
  );
};

export default Mantras;
