import React, { memo } from 'react';
import style from './styles.module.scss';
import { BUCKET } from '../../../constants/api';
import moment from 'moment/moment';
import HorizontalDotsIcon from '../../../assets/svg/horizontalDots';
import TrashIcon from '../../../assets/svg/trash';

const UserResponsesComponent = memo(
  ({ message, openMenuId, handleDelete, menuRef, handleMenuToggle }) => {
    return (
      <div className={style.messageBlock}>
        <div className={style.avatarBlock}>
          {message.user.photo ? (
            <img src={`${BUCKET}/${message.user.photo}`} alt="#" />
          ) : (
            <div className={style.imagePlaceholder} />
          )}
        </div>

        <div className={style.answerBlockWrapper}>
          <div className={style.messageHeader}>
            <div className={style.messageInfo}>
              <p>{message.user.name}</p>
              <span>{moment(message.createdAt).format('h:mm A')}</span>
            </div>

            <div className={style.dotsBlock} onClick={() => handleMenuToggle(message._id)}>
              <HorizontalDotsIcon />

              {openMenuId === message._id && (
                <div className={style.subMenuWrapper} ref={menuRef}>
                  <ul>
                    <li onClick={() => handleDelete(message._id)}>
                      <TrashIcon />
                      <p>Delete</p>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>

          <div className={style.messageContent}>
            <p>{message.answer}</p>
          </div>
        </div>
      </div>
    );
  }
);

export default UserResponsesComponent;
