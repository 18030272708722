import React, { useEffect, useState } from "react";
import style from "./styles.module.scss";
import TableHeader from "../../blocks/common/tableHeader";
import TableBody from "../../blocks/common/tableBody";
import { daysData } from "../../constants/selectArrays";
import { fetchRequest } from "../../API/fetchRequest";

const tableColumn = [
  { type: "checkbox", width: "3.5%" },
  { title: "Product", type: "product", width: "11.3%" },
  { title: "Post title", type: "title", width: "16.01%" },
  { title: "Category", type: "category", width: "17.3%" },
  { title: "Type", type: "type", width: "20.1%" },
  { title: "Date of publications", type: "date", width: "17.5%" },
  { title: "Edit or Delate", type: "editOrDelete", width: "14%" },
];

const PostManagement = () => {
  const [posts, setPosts] = useState([]);

  const [query, setQuery] = useState("");
  const [page, setPage] = useState("Home");
  const [type, setType] = useState("Music");

  const [days, setDays] = useState({ name: "All time", value: null });

  const [loading, setLoading] = useState(true);

  const daysNames = daysData.map((item) => item.name);

  const saveDays = (val) => {
    const day = daysData.find((item) => item.name === val);
    setDays(day);
  };

  const handleQueryChange = (val) => {
    setQuery(val);
    if (val.length === 0) {
      getPosts(true);
    }
  };

  const getPosts = async (searchQuery) => {
    setLoading(true);
    const data = {
      page,
      title: searchQuery ? "" : query,
      type,
    };
    data.days = days.value;
    const resp = await fetchRequest("POST", "admin/posts", data, true);
    setLoading(false);

    setPosts(resp.posts);
  };

  const removePost = async (id) => {
    const resp = await fetchRequest(
      "delete",
      "admin/deletepost",
      { postId: id },
      true
    );

    if (resp.success) {
      getPosts();
    }
  };

  useEffect(() => {
    getPosts();
    // eslint-disable-next-line
  }, [page, days, type]);

  return (
    <div className={style.postManage}>
      <TableHeader
        isPostManage
        query={query}
        page={page}
        days={daysNames}
        type={type}
        choosedDay={days.name}
        setType={setType}
        setQuery={handleQueryChange}
        setPage={setPage}
        saveDays={saveDays}
        onSearchClick={getPosts}
      />
      <TableBody
        tableColumn={tableColumn}
        data={posts}
        removeItem={removePost}
        loading={loading}
        updateTable={getPosts}
      />
    </div>
  );
};

export default PostManagement;
