import React, { useEffect, useState } from 'react';
import style from './styles.module.scss';

import CentralBlock from '../../blocks/createPost/centralBlock';
import RightBlock from '../../blocks/createPost/rightBlock';
import { useFormik } from 'formik';
import { fetchRequest } from '../../API/fetchRequest';
import { pages, postTypes } from '../../constants/selectArrays';
import { fetchFormDataRequest } from '../../API/fetchFormDataRequest';
import { Modal } from '../../components';
import { CreatePostShema } from '../../utils/validations/CreatePostShema';
import moment from 'moment';

const CreatePost = () => {
  const [categories, setCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const formik = useFormik({
    initialValues: {
      page: '',
      type: '',
      category: '',
      date: new Date(),
      time: '',
      timeOfDay: 'pm',
      cover: null,
      audio: null,
      title: '',
      description: '',
      featured: false,
      notification: false,
      main: false,
      duration: 0
    },
    validationSchema: CreatePostShema,
    onSubmit: async (values, { resetForm, setErrors }) => {
      if (values.time.length !== 0) {
        let futureDate = getFullDate().fullDateInMoment;
        let currentDate = moment(new Date()).format('x');

        if (moment(futureDate).format('x') < currentDate) {
          setErrors({ time: 'Enter a future date' });
          return;
        }
      }

      setShowModal(true);
    }
  });

  const getCategories = async (neededDefault) => {
    const data = {
      page: formik.values.page,
      title: '',
      days: null,
      category: '',
      default: true
    };
    const resp = await fetchRequest('POST', 'admin/categories', data, true);

    if (resp.default) {
      let data = [...resp.default, ...resp.categories];
      setCategories(data);
    } else {
      setCategories(resp.categories);
    }
  };

  const onChange = (value, name) => {
    if (value !== pages[0] && name === 'page') {
      formik.setFieldValue('type', postTypes[0]);
    }
    if (value === postTypes[1] && name === 'type') {
      formik.setFieldValue('category', '');
      formik.setFieldValue('featured', false);
      formik.setFieldValue('main', false);
    }

    if (name === 'page') {
      formik.setFieldValue('category', '');
    }

    if (name === 'featured' && value === false) {
      formik.setFieldValue('main', false);
    }
    formik.setFieldValue(name, value);
  };

  const categoriesNames = categories.map((item) => item.title);

  const getFullDate = () => {
    let date = formik.values.date;
    let time = formik.values.time;
    let timeOfDay = formik.values.timeOfDay;
    let convDate = moment(date).format('MM/DD/YYYY');

    if (timeOfDay === 'am') {
      convDate = convDate + ` ${time}`;
    } else {
      let timeParts = time.split(':');
      let hours = Number(timeParts[0]) + 12;

      convDate = convDate + ` ${hours}:${timeParts[1]}`;
    }

    let fullDateInMoment = moment(convDate, 'MM/DD/YYYY hh:mm');

    let utcDate = new Date(fullDateInMoment).toISOString();

    let utcMoment = moment.utc(utcDate).format('MM/DD/YYYY HH:mm');

    return { convDate: utcMoment, fullDateInMoment };
  };

  const onSubmit = async () => {
    let futureDate = getFullDate().convDate;
    const values = formik.values;
    const formData = new FormData();
    Object.keys(values).forEach((item) => {
      if (item === 'date') {
        formData.append('date', futureDate);
      } else {
        formData.append(item, values[item]);
      }
    });

    formData.append('futured', values.time.length !== 0);
    formData.append('publishDate', String(values.date));

    const resp = await fetchFormDataRequest('POST', 'admin/post', formData, true);
    setShowModal(false);
    if (resp && resp.success) {
      formik.resetForm();
    }
  };

  useEffect(() => {
    if (formik.values.page.length > 0) {
      getCategories(formik.values.page === pages[0]);
    }
    // eslint-disable-next-line
  }, [formik.values.page]);

  return (
    <div className={style.createPost}>
      {showModal && (
        <Modal
          text="Are you sure you want to save this post?"
          onClose={() => setShowModal(false)}
          onSubmit={onSubmit}
        />
      )}
      <CentralBlock formik={formik} onChange={onChange} categories={categoriesNames} />
      <RightBlock
        formik={formik}
        onChange={onChange}
        onSubmit={() => {
          formik.submitForm();
        }}
      />
    </div>
  );
};

export default CreatePost;
