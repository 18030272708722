import React, { useEffect, useState } from "react";
import style from "./styles.module.scss";
import TableHeader from "../../common/tableHeader";
import TableBody from "../../common/tableBody";
import { fetchRequest } from "../../../API/fetchRequest";
import { daysData } from "../../../constants/selectArrays";
import { useSelector, useDispatch } from "react-redux";
import { setCategories as updateDefCategories } from "../../../redux/actions/categories";
const tableColumn = [
  { type: "arrows", width: "5%" },
  { title: "Product", type: "product", width: "18.1%" },
  { title: "Post title", type: "title", width: "25.9%" },
  { title: "Choose Category", type: "page", width: "30.7%" },
  { title: "Edit  or Delete", type: "editOrDelete", width: "18.3%" },
];

const CentralBlock = () => {
  const [categories, setCategories] = useState();
  const dispatch = useDispatch();
  const { categories: defCategories } = useSelector(
    (state) => state.categoriesStore
  );

  const [query, setQuery] = useState("");
  const [page, setPage] = useState("Home");
  const [days, setDays] = useState({ name: "All time", value: null });

  const [loading, setLoading] = useState(false);
  const daysNames = daysData.map((item) => item.name);

  const saveDays = (val) => {
    const day = daysData.find((item) => item.name === val);
    setDays(day);
  };

  const handleQueryChange = (val) => {
    setQuery(val);
    if (val.length === 0) {
      getCategories(true);
    }
  };

  const getCategories = async (searchQuery) => {
    setLoading(true);
    const data = {
      page,
      title: searchQuery ? "" : query,
    };
    data.days = days.value;
    const resp = await fetchRequest("POST", "admin/categories", data, true);

    setLoading(false);
    setCategories(resp.categories);
  };

  const onChangeOrder = async (categoryId, up) => {
    let data = [...categories];
    const index = categories.findIndex((item) => item._id === categoryId);
    if (index > -1) {
      if (up) {
        let item = { ...categories[index - 1] };
        data[index - 1] = { ...categories[index] };
        data[index] = item;
        setCategories(data);
      } else {
        let item = { ...categories[index + 1] };
        data[index + 1] = { ...categories[index] };
        data[index] = item;
        setCategories(data);
      }
    }

    const ids = data.map((item) => item._id);

    const resp = await fetchRequest(
      "PUT",
      "admin/categories/order",
      { ids },
      true
    );

    console.log("RESPPRESPPRESP", resp);
  };

  const removeCategory = async (id) => {
    const resp = await fetchRequest(
      "delete",
      "admin/category",
      { categoryId: id },
      true
    );

    if (resp.success) {
      getCategories();
    }
  };

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line
  }, [page, days]);

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line
  }, []);

  console.log(categories);

  return (
    <div className={style.centralBlock}>
      <TableHeader
        defCategories={defCategories}
        query={query}
        page={page}
        days={daysNames}
        choosedDay={days.name}
        setQuery={handleQueryChange}
        setPage={setPage}
        saveDays={saveDays}
        onSearchClick={getCategories}
      />
      <TableBody
        onChangeOrder={onChangeOrder}
        loading={loading}
        isCategoryTable={true}
        tableColumn={tableColumn}
        data={categories}
        removeItem={removeCategory}
        updateTable={getCategories}
      />
    </div>
  );
};

export default CentralBlock;
