import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import admins from './reducers/admins';
import users from './reducers/users';
import posts from './reducers/posts';
import categories from './reducers/categories';
import horoscope from './reducers/horoscope';
import promptSlice from './slices/prompt';

const rootReducer = combineReducers({
  adminsStore: admins,
  usersStore: users,
  postsStore: posts,
  categoriesStore: categories,
  horoscopeStore: horoscope,
  promptStore: promptSlice
});

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;
