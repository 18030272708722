import React from 'react';

const EditIcon = ({ width = '16', height = '16', fill = 'none', stroke = '#000000' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66675 4.66663H4.00008C3.64646 4.66663 3.30732 4.8071 3.05727 5.05715C2.80722 5.3072 2.66675 5.64634 2.66675 5.99996V12C2.66675 12.3536 2.80722 12.6927 3.05727 12.9428C3.30732 13.1928 3.64646 13.3333 4.00008 13.3333H10.0001C10.3537 13.3333 10.6928 13.1928 10.9429 12.9428C11.1929 12.6927 11.3334 12.3536 11.3334 12V11.3333M10.6667 3.33329L12.6667 5.33329M13.5901 4.38995C13.8526 4.12738 14.0002 3.77127 14.0002 3.39995C14.0002 3.02863 13.8526 2.67251 13.5901 2.40995C13.3275 2.14738 12.9714 1.99988 12.6001 1.99988C12.2288 1.99988 11.8726 2.14738 11.6101 2.40995L6.00008 7.99995V9.99995H8.00008L13.5901 4.38995Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditIcon;
