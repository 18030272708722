import React from "react";
import style from "./styles.module.scss";
import LeftPanel from "../../blocks/common/leftPanel";
import { Switch, Route, Redirect } from "react-router-dom";
import { getSuperAdmin } from "../../utils/superAdminLS";
import {
  CreatePost,
  PostManagement,
  CreateAndManage,
  UsersManagment,
  SettingsPage,
  Notification,
  Horoscope,
  Mantras,
  Banner
} from "../../pages";
import PromptPage from "../../pages/PromptPage";

const PrivateNavigation = () => {
  const isSuperAdmin = getSuperAdmin();
  return (
    <div className={style.prNavigation}>
      <LeftPanel />
      <div className={style.prNavigation__content}>
        <Switch>
          <Route exact path="/">
            <Redirect to="/createPost" />
          </Route>
          <Route path="/createPost" exact={true} component={CreatePost} />
          <Route
            path="/postManagement"
            exact={true}
            component={PostManagement}
          />
          <Route
            path="/banner"
            exact={true}
            component={Banner}
          />
          <Route
              path="/prompt"
              exact={true}
              component={PromptPage}
          />
          <Route
            path="/createAndManage"
            exact={true}
            component={CreateAndManage}
          />
          <Route
            path="/usersManagment"
            exact={true}
            component={UsersManagment}
          />
          <Route path="/mantras" exact={true} component={Mantras} />
          {isSuperAdmin && (
            <Route path="/settings" exact={true} component={SettingsPage} />
          )}

          <Route path="/notification" exact={true} component={Notification} />
          <Route path="/horoscope" exact={true} component={Horoscope} />
          <Route exact path="*" component={() => <> 404</>} />
        </Switch>
      </div>
    </div>
  );
};

export default PrivateNavigation;
