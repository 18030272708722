import { SET_HOROSCOPE_INFO } from "../actionTypes/horoscope";
import { fetchRequest } from "../../API/fetchRequest";

export const setHoroscopeInfo = (payload) => ({
  type: SET_HOROSCOPE_INFO,
  payload,
});

export const getHoroscopeInfo = () => async (dispatch) => {
  const { info, success } = await fetchRequest(
    "GET",
    "admin/taro",
    undefined,
    true
  );
  if (success) {
    delete info["updatedAt"];
    delete info["_id"];
    dispatch(setHoroscopeInfo(info));
  }
};
