import { SET_CATEGORIES, CLEAR_CATEGORIES } from "../actionTypes/categories";

const defaultState = {
  categories: [],
};

const categories = (state = defaultState, action) => {
  switch (action.type) {
    case SET_CATEGORIES: {
      return {
        ...state,
        categories: [...action.payload],
      };
    }
    case CLEAR_CATEGORIES: {
      return { ...state, categories: [] };
    }

    default:
      return state;
  }
};
export default categories;
