import React from 'react';
import style from './styles.module.scss';
import AppButton from '../../../components/AppButton';
import { DatePicker } from '../../../components';

const CreatePromptComponent = ({
  handleClose,
  handleSubmit,
  date,
  setDate,
  promptText,
  setPromptText,
  label = ''
}) => {
  const handleChangeDate = (value) => {
    console.log('Select date: ', value);
    setDate(value);
  };

  return (
    <div className={style.modalComponentWrapper}>
      <h2>{label}</h2>
      <div className={style.modalFieldsWrapper}>
        <div className={style.modalFieldsBlock}>
          <div className={style.modalField}>
            <h5>Choose Date</h5>
            <DatePicker value={new Date(date)} onChange={handleChangeDate} />
          </div>
        </div>

        <div className={style.textAreaField}>
          <h5>Enter prompt</h5>
          <div className={style.textAreaFieldItem}>
            <textarea
              value={promptText}
              onChange={(event) => setPromptText(event.target.value)}
              rows={7}
            />
          </div>
        </div>

        <div className={style.modalButtonBlock}>
          <AppButton text="Cancel" onClick={handleClose} />
          <AppButton text="Save" className={style.modalButton} onClick={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

export default CreatePromptComponent;
