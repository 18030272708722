import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import store from './redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { SocketProvider } from './context/socket';

import './scss/style.scss';

ReactDOM.render(
  <React.StrictMode>
    <SocketProvider>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </SocketProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
