import React, { useState } from "react";
import style from "./styles.module.scss";
import { pages, postTypes } from "../../../constants/selectArrays";

import {
  Select,
  Checkbox,
  Switch,
  DatePicker,
  TimePicker,
} from "../../../components";

const CentralBlock = ({ formik, onChange, categories = [] }) => {
  return (
    <div className={style.centralBlock}>
      <div className={style.centralBlock__left}>
        <Select
          label="Select a page"
          data={pages}
          formik={formik}
          name="page"
          onChange={onChange}
          placeholder="Choose"
          error={formik.touched.page && formik.errors.page}
        />
        <div className={style.centralBlock__leftSelect}>
          <Select
            label="Select a post type"
            placeholder="Choose"
            data={postTypes}
            formik={formik}
            name="type"
            onChange={onChange}
            isDisabled={formik.values.page !== pages[0]}
            error={formik.touched.type && formik.errors.type}
          />
        </div>

        {formik.values.page !== pages[0] && (
          <div className={style.centralBlock__checkbox}>
            <Checkbox
              value={formik.values.featured}
              isDisabled={formik.values.type === postTypes[1]}
              onClick={() => onChange(!formik.values.featured, "featured")}
            />
            <div className={style.centralBlock__checkboxTitle}>Featured</div>
          </div>
        )}

        <div
          className={
            formik.values.page === pages[0]
              ? style.centralBlock__checkbox
              : style.centralBlock__checkboxMain
          }
        >
          <Checkbox
            value={formik.values.main}
            onClick={() => onChange(!formik.values.main, "main")}
            isDisabled={formik.values.type === postTypes[1]}
          />
          <div className={style.centralBlock__checkboxTitle}>
            Main featured post
          </div>
        </div>

        <div className={style.centralBlock__switch}>
          <Switch
            value={formik.values.notification}
            onClick={() =>
              onChange(!formik.values.notification, "notification")
            }
          />
          <div className={style.centralBlock__switchTitle}>
            Send notification
          </div>
        </div>
      </div>

      <div className={style.centralBlock__right}>
        <Select
          label="Category"
          placeholder="Choose"
          formik={formik}
          name="category"
          data={categories}
          onChange={onChange}
          isDisabled={
            categories.length === 0 || formik.values.type === postTypes[1]
          }
          error={formik.touched.category && formik.errors.category}
        />
        <div className={style.centralBlock__rightDatePicker}>
          <DatePicker
            onChange={onChange}
            name={"date"}
            formik={formik}
            value={formik.values.date}
            label="Date"
            // isDisabled={formik.values.type === postTypes[1]}
          />
        </div>

        <div className={style.centralBlock__rightTimePicker}>
          <TimePicker
            formik={formik}
            onChange={onChange}
            label="Time"
            timeName="timeOfDay"
            name="time"
            // isDisabled={formik.values.type === postTypes[1]}
            error={formik.touched.time && formik.errors.time}
          />
        </div>
      </div>
    </div>
  );
};

export default CentralBlock;
