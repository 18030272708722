import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import style from './styles.module.scss';
import { useSocket } from '../../hooks/socket';
import { HiSearch } from 'react-icons/hi';
import PlantIcon from '../../assets/svg/plant';
import { useDispatch, useSelector } from 'react-redux';
import {
  createSearchRequestThunks,
  deleteMessageThunks,
  getMessagesThunks,
  getPromptsThunks,
  getPromptThunks,
  lazyLoadMessagesThunks
} from '../../redux/thunks/prompt';
import AppContentLoaderComponent from '../../components/ContentLoader';
import { addMessageToChat, clearMessages } from '../../redux/slices/prompt';
import useOutsideClick from '../../hooks/outside-click';
import UserResponsesComponent from '../../blocks/prompt/responses';
import PromptComponent from '../../blocks/prompt/prompt';
import { debounce } from '../../utils/debounce';

const PromptPage = () => {
  const [openMenuId, setOpenMenuId] = useState(null);
  const [searchPhrase, setSearchPhrase] = useState('');
  const menuRef = useRef(null);
  const messageBlockRef = useRef(null);
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.promptStore.messages);
  const answersCount = useSelector((state) => state.promptStore.answersCount);
  const isLoading = useSelector((state) => state.promptStore.isLoading);
  const prompt = useSelector((state) => state.promptStore.prompt);
  const page = useSelector((state) => state.promptStore.page);
  const totalPages = useSelector((state) => state.promptStore.totalPages);
  const socket = useSocket();
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    dispatch(getPromptThunks());
    dispatch(getPromptsThunks({ page: 1 }));
    dispatch(getMessagesThunks({ page: 1 }));
  }, [dispatch]);

  useEffect(() => {
    const handleSearch = debounce(() => {
      if (searchPhrase.length !== 0) {
        dispatch(createSearchRequestThunks({ phrase: searchPhrase }));
      } else {
        dispatch(getMessagesThunks({ page: 1 }));
      }
    }, 500);

    handleSearch();
  }, [dispatch, searchPhrase]);

  useEffect(() => {
    const handleScroll = async () => {
      if (messageBlockRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = messageBlockRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 200 && page < totalPages && !isLoading) {
          setIsFirstLoad(false);
          await dispatch(lazyLoadMessagesThunks({ page: Number(page) + 1 }));
        }
      }
    };

    const messageBlock = messageBlockRef.current;
    if (messageBlock) {
      messageBlock.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (messageBlock) {
        messageBlock.removeEventListener('scroll', handleScroll);
      }
    };
  }, [dispatch, page, totalPages, isLoading]);

  useEffect(() => {
    if (!socket) return;

    socket.on('newMessage', (message) => {
      dispatch(addMessageToChat(message.newMessage));
    });

    socket.on('deleteAllPromptsData', () => {
      dispatch(clearMessages());
    });

    return () => {
      socket.off('newMessage');
      socket.off('deleteAllPromptsData');
    };
  }, [dispatch, prompt, socket]);

  useEffect(() => {
    if (messageBlockRef.current && isFirstLoad) {
      messageBlockRef.current.scrollTop = 0;
    }
  }, [messages, isFirstLoad]);

  useEffect(() => {
    if (messageBlockRef.current && !isFirstLoad) {
      const messageBlock = messageBlockRef.current;
      const prevScrollHeight = messageBlock.scrollHeight - messageBlock.clientHeight;

      const observer = new MutationObserver(() => {
        messageBlock.scrollTop = messageBlock.scrollHeight - prevScrollHeight;
      });

      observer.observe(messageBlock, { childList: true, subtree: true });

      return () => {
        observer.disconnect();
      };
    }
  }, [messages, isFirstLoad]);

  const handleDelete = useCallback(
    (messageId) => {
      dispatch(deleteMessageThunks({ messageId }));
    },
    [dispatch]
  );

  const handleMenuToggle = useCallback((messageId) => {
    setOpenMenuId((prevId) => (prevId === messageId ? null : messageId));
  }, []);

  useOutsideClick(menuRef, () => setOpenMenuId(null));

  return (
    <div className={style.promptWrapper}>
      <div className={style.promptBlock}>
        <h2 className={style.promptHeaderTitle}>User Responses</h2>

        <div className={style.searchContainer}>
          <span className={style.searchIcon}>
            <HiSearch />
          </span>
          <input
            type="text"
            className={style.searchInput}
            placeholder="Search..."
            value={searchPhrase}
            onChange={(e) => setSearchPhrase(e.target.value)}
          />
        </div>

        <div className={style.responseCount}>
          <p>Total responses: {answersCount}</p>
        </div>

        <div className={style.promptInfo}>
          <div className={style.promptDateBlock}>
            <div className={style.promptDate}>
              {prompt && prompt.publishDate ? (
                <>
                  <h5>{new Date(prompt.publishDate).getUTCDate()}</h5>
                  <p>
                    {moment(new Date()).format('MMM')},
                    {moment(new Date(prompt.publishDate)).format('ddd')}
                  </p>
                </>
              ) : (
                <>
                  <h5>{moment(new Date()).format('D')}</h5>
                  <p>
                    {moment(new Date()).format('MMM')}, {moment(new Date()).format('ddd')}
                  </p>
                </>
              )}
            </div>
          </div>

          <div className={style.promptContent}>
            <p>{prompt && prompt.message ? prompt.message : 'There are no entries here yet.'}</p>
            <PlantIcon />
          </div>
        </div>

        {isLoading && <AppContentLoaderComponent />}

        <div className={style.messageBlockWrapper} ref={messageBlockRef}>
          {messages.map((message) => (
            <UserResponsesComponent
              message={message}
              handleDelete={handleDelete}
              handleMenuToggle={handleMenuToggle}
              menuRef={menuRef}
              openMenuId={openMenuId}
              key={message._id}
            />
          ))}
        </div>
      </div>

      <div className={style.promptScheduledBlock}>
        <PromptComponent />
      </div>
    </div>
  );
};

export default PromptPage;
