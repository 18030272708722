import React from "react";

const postManage = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7508 21.9397H13.4978C13.0838 21.9397 12.7478 21.6037 12.7478 21.1897C12.7478 20.7757 13.0838 20.4397 13.4978 20.4397H20.7508C21.1648 20.4397 21.5008 20.7757 21.5008 21.1897C21.5008 21.6037 21.1648 21.9397 20.7508 21.9397Z"
        fill="#808191"
      />
      <mask
        id="mask0_202:1960"
        maskUnits="userSpaceOnUse"
        x="2"
        y="3"
        width="18"
        height="19"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.00034 3.00024H19.1809V21.9398H2.00034V3.00024Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_202:1960)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.1106 5.01689L3.69561 16.7919C3.52461 17.0059 3.46161 17.2819 3.52461 17.5469L4.20561 20.4319L7.24461 20.3939C7.53361 20.3909 7.80061 20.2619 7.97761 20.0419C11.1946 16.0169 17.3276 8.34289 17.5016 8.11789C17.6656 7.85189 17.7296 7.47589 17.6436 7.11389C17.5556 6.74289 17.3246 6.42789 16.9916 6.22689C16.9206 6.17789 15.2356 4.86989 15.1836 4.82889C14.5496 4.32089 13.6246 4.40889 13.1106 5.01689ZM3.61361 21.9399C3.26661 21.9399 2.96461 21.7019 2.88361 21.3629L2.06461 17.8919C1.89561 17.1729 2.06361 16.4309 2.52461 15.8549L11.9446 4.07289C11.9486 4.06889 11.9516 4.06389 11.9556 4.05989C12.9886 2.82489 14.8566 2.64289 16.1166 3.65389C16.1666 3.69289 17.8396 4.99289 17.8396 4.99289C18.4476 5.35489 18.9226 6.00189 19.1026 6.76789C19.2816 7.52589 19.1516 8.30789 18.7346 8.96889C18.7036 9.01789 18.6766 9.05989 9.14861 20.9799C8.68961 21.5519 8.00161 21.8849 7.26261 21.8939L3.62361 21.9399H3.61361Z"
          fill="#808191"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2234 11.6849C16.0634 11.6849 15.9034 11.6339 15.7664 11.5299L10.3144 7.34187C9.98642 7.08987 9.92442 6.61987 10.1764 6.28987C10.4294 5.96187 10.8994 5.90087 11.2284 6.15287L16.6814 10.3399C17.0094 10.5919 17.0714 11.0629 16.8184 11.3919C16.6714 11.5839 16.4484 11.6849 16.2234 11.6849Z"
        fill="#808191"
      />
    </svg>
  );
};

export default postManage;
