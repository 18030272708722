import React from 'react';
import style from './styles.module.scss';
import { NotifAppIcon } from '../../assets/svg';
import moment from 'moment';

const NotificationItem = ({ item }) => {
  let date = moment(item.createdAt).format('DD MMM hh:mm a');
  return (
    <div className={style.notificationItem}>
      <div className={style.notificationItem__header}>
        <NotifAppIcon />
        <div className={style.notificationItem__headerDate}>{date}</div>
      </div>

      <div className={style.notificationItem__title}>{item.title}</div>
      <div className={style.notificationItem__description}>{item.body}</div>
    </div>
  );
};

export default NotificationItem;
