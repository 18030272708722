import React, { useEffect, useState } from "react";
import {
  DropZone,
  Button,
  Input,
  TextArea,
  Select,
  Modal,
  Checkbox,
  Switch,
} from "..";
import { fetchFormDataRequest } from "../../API/fetchFormDataRequest";
import style from "./styles.module.scss";
import { EditPostShems } from "../../utils/validations/EditPostShema";
import { SUPPORTED_COVER_FORMATS } from "../../constants/supportedCoverFormats";
import { useFormik } from "formik";
import { pages, postTypes } from "../../constants/selectArrays";
import { fetchRequest } from "../../API/fetchRequest";
const PostItemEdit = ({ item, updateTable }) => {
  const [showModal, setShowModal] = useState(false);
  const [categories, setCategories] = useState([]);

  const formik = useFormik({
    initialValues: {
      page: "",
      type: "",
      cover: null,
      title: "",
      subtitle: "",
      description: "",
      category: "",
      audio: null,
      featured: false,
      notification: false,
      main: false,
    },
    validationSchema: EditPostShems,
    onSubmit: async (values, { resetForm }) => {
      setShowModal(true);
    },
  });

  const onChange = (value, name) => {
    if (value !== pages[0] && name === "page") {
      formik.setFieldValue("type", postTypes[0]);
    }
    if (value === postTypes[1] && name === "type") {
      formik.setFieldValue("category", "");
      formik.setFieldValue("featured", false);
      formik.setFieldValue("main", false);
    }

    if (name === "page") {
      formik.setFieldValue("category", "");
    }

    if (name === "featured" && value === false) {
      formik.setFieldValue("main", false);
    }

    formik.setFieldValue(name, value);
  };

  const categoriesNames = categories.map((item) => item.title);

  const saveCover = (file) => {
    if (SUPPORTED_COVER_FORMATS.find((item) => item === file[0].type)) {
      onChange(file[0], "cover");
    }
  };

  const onSubmit = async () => {
    const values = formik.values;
    const formData = new FormData();
    Object.keys(values).forEach((item) => {
      formData.append(item, values[item]);
    });

    formData.append("postId", item._id);

    const resp = await fetchFormDataRequest(
      "PUT",
      "admin/editpost",
      formData,
      true
    );
    setShowModal(false);
    if (resp && resp.success) {
      updateTable && updateTable();
      formik.resetForm();
    }
  };

  const getCategories = async (searchQuery) => {
    const data = {
      page: formik.values.page,
      title: "",
      days: null,
      category: "",
      default: true,
    };
    const resp = await fetchRequest("POST", "admin/categories", data, true);
    if (resp.default) {
      let data = [...resp.default, ...resp.categories];
      setCategories(data);
    } else {
      setCategories(resp.categories);
    }
  };

  useEffect(() => {
    if (formik.values.page.length > 0) {
      if (formik.values.page !== item.page && formik.values.category !== "") {
        formik.setFieldValue("category", "");
      }
      getCategories();
    }
    // eslint-disable-next-line
  }, [formik.values.page]);

  useEffect(() => {
    console.log("ITEM", item);
    getCategories(item.page);
    formik.setFieldValue("title", item.title);
    formik.setFieldValue("description", item.description);
    formik.setFieldValue("page", item.page);
    formik.setFieldValue("subtitle", item.subtitle);
    formik.setFieldValue("category", item.category ? item.category : "");
    formik.setFieldValue("featured", item.featured);
    formik.setFieldValue("notification", item.notification);
    formik.setFieldValue("type", item.type);
    formik.setFieldValue("main", item?.main);
    // eslint-disable-next-line
  }, []);

  return (
    <div className={style.itemEdit}>
      {showModal && (
        <Modal
          text="Are you sure you want to save the Settings?"
          onSubmit={onSubmit}
          onClose={() => setShowModal(false)}
        />
      )}
      <div className={style.itemEdit__left}>
        <div className={style.itemEdit__leftDropZone}>
          <DropZone
            file={formik.values.cover}
            setFile={saveCover}
            removeFile={() => onChange(null, "cover")}
            label="Photo"
          />
        </div>

        {/* {item.featured && ( */}

        {formik.values.page !== pages[0] && (
          <div className={style.itemEdit__checkbox}>
            <Checkbox
              value={formik.values.featured}
              onClick={() => onChange(!formik.values.featured, "featured")}
            />
            <div className={style.itemEdit__checkboxTitle}>Featured</div>
          </div>
        )}

        {/* )} */}

        {/* {item.featured && ( */}
        <div className={style.itemEdit__checkbox}>
          <Checkbox
            value={formik.values.main}
            onClick={() => onChange(!formik.values.main, "main")}
          />
          <div className={style.itemEdit__checkboxTitle}>
            Main featured post
          </div>
        </div>
        {/* )} */}

        {/* {item.featured && (
          <div className={style.itemEdit__switch}>
            <Switch
              value={formik.values.notification}
              onClick={() =>
                onChange(!formik.values.notification, "notification")
              }
            />
            <div className={style.itemEdit__switchTitle}>Send notification</div>
          </div>
        )} */}
      </div>

      <div className={style.itemEdit__central}>
        <Input
          label="Title"
          formik={formik}
          onChange={onChange}
          name="title"
          error={formik.touched.title && formik.errors.title}
        />
        <div className={style.itemEdit__block}>
          <TextArea
            formik={formik}
            onChange={onChange}
            name="description"
            label="Post Description"
            error={formik.touched.description && formik.errors.description}
          />
        </div>
      </div>

      <div className={style.itemEdit__right}>
        <Select
          label="Page"
          placeholder="Choose"
          formik={formik}
          name="page"
          data={pages}
          onChange={onChange}
        />
        <div className={style.itemEdit__block}>
          <Select
            label="Category"
            placeholder="Choose"
            formik={formik}
            name="category"
            data={categoriesNames}
            onChange={onChange}
            isDisabled={
              categories.length === 0 || formik.values.type === postTypes[1]
            }
            error={formik.touched.category && formik.errors.category}
          />
        </div>
        <div className={style.itemEdit__block}>
          <Select
            label="Post type"
            placeholder="Choose"
            formik={formik}
            name="type"
            data={postTypes}
            onChange={onChange}
            isDisabled={formik.values.page !== "Home"}
            error={formik.touched.type && formik.errors.type}
          />
        </div>
        <div className={style.itemEdit__button}>
          <Button text="Save changes" onClick={() => formik.submitForm()} />
        </div>
      </div>
    </div>
  );
};

export default PostItemEdit;
