import React from "react";
import style from "./styles.module.scss";
import clsx from "clsx";
import { Select } from "../../../components";
import { SearchInput, ColorButton } from "../../../components";
import { pages, postTypes } from "../../../constants/selectArrays";
const Header = ({
  isPostManage,
  query,
  setQuery,
  page,
  setPage,
  days,
  choosedDay,
  saveDays,
  onSearchClick,
  type,
  setType,
  defCategories,
}) => {
  if (isPostManage) {
    return (
      <div className={style.header}>
        <div className={style.header__row}>
          <div className={style.header__select}>
            <Select data={days} value={choosedDay} onChange={saveDays} />
          </div>
          <div className={style.header__select}>
            <Select data={pages} value={page} onChange={setPage} />
          </div>
          <div className={style.header__select}>
            <Select data={postTypes} value={type} onChange={setType} />
          </div>
          <div className={style.header__searchInput}>
            <SearchInput
              value={query}
              onChange={setQuery}
              onClick={onSearchClick}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={style.header}>
      <div className={style.header__row}>
        <div className={style.header__select}>
          <Select data={pages} value={page} onChange={setPage} header={true} />
        </div>
        <div className={style.header__select}>
          <Select
            data={days}
            value={choosedDay}
            onChange={saveDays}
            header={true}
          />
        </div>
        {/* </div> */}

        <div className={style.header__searchInput}>
          <SearchInput
            value={query}
            onChange={setQuery}
            onClick={onSearchClick}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
