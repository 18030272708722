import React from 'react';
import style from './styles.module.scss';
import AppButton from '../../../components/AppButton';
import { Close } from '../../../assets/svg';

const DeletePromptsComponent = ({ handleDelete, handleClose }) => {
  return (
    <div className={style.deleteComponentWrapper}>
      <div className={style.deleteComponentHeader}>
        <h2>Are you sure you want to delete all selected prompts?</h2>
        <div className={style.closeIconBlock} onClick={handleClose}>
          <Close />
        </div>
      </div>
      <p>You won’t be able to undo this action.</p>
      <div className={style.deleteButtonBlock}>
        <AppButton text="Cancel" onClick={handleClose} />
        <AppButton text="Delete" className={style.deleteButton} onClick={handleDelete} />
      </div>
    </div>
  );
};

export default DeletePromptsComponent;
