import React from "react";

const close = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.22517 0.81096C1.83465 0.420435 1.20148 0.420435 0.81096 0.81096C0.420435 1.20148 0.420435 1.83465 0.81096 2.22517L6.58569 7.99991L0.811019 13.7746C0.420495 14.1651 0.420495 14.7983 0.811019 15.1888C1.20154 15.5793 1.83471 15.5793 2.22523 15.1888L7.99991 9.41412L13.7746 15.1888C14.1651 15.5793 14.7983 15.5793 15.1888 15.1888C15.5793 14.7983 15.5793 14.1651 15.1888 13.7746L9.41412 7.99991L15.1889 2.22517C15.5794 1.83465 15.5794 1.20148 15.1889 0.81096C14.7983 0.420435 14.1652 0.420435 13.7746 0.81096L7.99991 6.58569L2.22517 0.81096Z"
        fill="black"
      />
    </svg>
  );
};

export default close;
