import React from 'react';
import style from './styles.module.scss';
import { Button, DropZone, Input, TextArea } from '../../../components';
import clsx from 'clsx';
import { postTypes } from '../../../constants/selectArrays';
import { SUPPORTED_COVER_FORMATS } from '../../../constants/supportedCoverFormats';
import { Close } from '../../../assets/svg';

const SUPPORTED_AUDIO_FORMAT = 'audio/mpeg';

const RightBlock = ({ formik, onChange, onSubmit }) => {
  const saveCover = (file) => {
    if (SUPPORTED_COVER_FORMATS.find((item) => item === file[0].type)) {
      onChange(file[0], 'cover');
    }
  };

  const saveMP3 = (file) => {
    if (file[0].type === SUPPORTED_AUDIO_FORMAT) {
      onChange(file[0], 'audio');
    }
  };

  return (
    <div className={style.rightBlock}>
      <div className={style.rightBlock__dropZone}>
        <DropZone
          setFile={saveCover}
          file={formik.values.cover}
          removeFile={() => onChange(null, 'cover')}
          error={formik.touched.cover && formik.errors.cover}
        />
      </div>

      <Input
        label="Post title"
        formik={formik}
        onChange={onChange}
        name="title"
        error={formik.touched.title && formik.errors.title}
      />

      <div className={style.rightBlock__textarea}>
        <TextArea
          formik={formik}
          onChange={onChange}
          name="description"
          label="Post Description"
          error={formik.touched.description && formik.errors.description}
        />
      </div>

      {formik.values.type !== postTypes[1] && formik.values.type !== '' && (
        <div className={style.rightBlock__mp3DropZone}>
          <DropZone
            formik={formik}
            setFile={saveMP3}
            file={formik.values.audio}
            removeFile={() => onChange(null, 'audio')}
            isMP3={true}
            error={formik.touched.audio && formik.errors.audio}
          />

          {formik.values.audio && (
            <div
              className={style.rightBlock__mp3DropZoneSvg}
              onClick={() => onChange(null, 'audio')}
            >
              <Close />
            </div>
          )}
        </div>
      )}

      <div
        className={clsx(style.rightBlock__button, {
          [style.margin20]: !!formik.values.audio
        })}
      >
        <Button text="Save post" onClick={onSubmit} />
      </div>
    </div>
  );
};

export default RightBlock;
