import React from 'react';
import style from './styles.module.scss';
import { Dandruff } from '../../assets/svg';
import { Button } from '..';

const SearchInput = ({ value, onChange, onClick }) => {
  return (
    <div className={style.seachBlock}>
      <div className={style.seachBlock__dandruff}>
        <Dandruff />
      </div>

      <input
        placeholder="Search"
        value={value}
        onChange={(e) => onChange && onChange(e.target.value)}
      />
      <div className={style.seachBlock__button}>
        <Button text="Search" onClick={() => onClick && onClick(null)} />
      </div>
    </div>
  );
};

export default SearchInput;
