import React from "react";
import style from "./styles.module.scss";
import CentralBlock from "../../blocks/createAndManage/centralBlock";
import RightBlock from "../../blocks/createAndManage/rightBlock";
const CreateAndManage = () => {
  return (
    <div className={style.createBlock}>
      <div className={style.createBlock__centralBlock}>
        <CentralBlock />
      </div>

      <RightBlock />
    </div>
  );
};

export default CreateAndManage;
