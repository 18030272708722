import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styles from "./styles.module.scss";

import { DropZone, Button } from "../../../components";

import { fetchFormDataRequest } from "../../../API/fetchFormDataRequest";
import { BUCKET } from "../../../constants/api";

const checkImageFormat = (value) => {
  if (typeof value === "string") return true;

  return value
    ? ["image/jpg", "image/jpeg", "image/png"].includes(value.type)
    : true;
};

export const AddMantra = ({ onAddMantra }) => {
  const formik = useFormik({
    initialValues: { cover: null },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      cover: Yup.mixed()
        .test("fileFormat", "Unsupported Format", checkImageFormat)
        .test("filePresent", "Please choose file", (value) => !!value),
    }),
    onSubmit: addMantra,
  });
  const { values, handleSubmit, setFieldValue, touched, errors } = formik;

  const renderImage = (img) => {
    if (typeof img === "string") {
      return `${BUCKET}/${img}`;
    }
    return img;
  };

  async function addMantra(values, { resetForm }) {
    const fd = new FormData();
    fd.append("cover", values.cover);

    const { success, created } = await fetchFormDataRequest(
      "POST",
      "admin/mantra",
      fd,
      true
    );
    if (success) {
      onAddMantra(created);
    } else {
      alert("Something went wrong");
    }

    resetForm();
  }

  const saveCover = (file, fieldName) => {
    if (file[0].type === "image/png" || file[0].type === "image/jpeg") {
      setFieldValue(fieldName, file[0]);
    }
  };

  return (
    <div>
      <h3>Add Mantra</h3>

      <div className={styles.wrapper__coverWrapper}>
        <DropZone
          setFile={(file) => saveCover(file, "cover")}
          file={renderImage(values.cover)}
          removeFile={() => setFieldValue("cover", null)}
          error={touched.cover && errors.cover}
        />
      </div>

      <div className={styles.wrapper__buttonContainer}>
        <Button text="Save Mantra" onClick={handleSubmit} />
      </div>
    </div>
  );
};
