import { Switch, Route } from "react-router-dom";
import { SignIn } from "../pages";
import PrivateRoute from "./PrivateRoute";

const MainNavigation = () => {
  return (
    <Switch>
      <Route path="/signIn" exact={true} component={SignIn} />

      <Route path="/" exact={false} component={PrivateRoute} />

      <Route exact path="*" component={() => <> 404</>} />
    </Switch>
  );
};

export default MainNavigation;
