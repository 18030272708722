export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    minWidth: '600px',
    borderRadius: '10px',
    backgroundColor: '#ffffff'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)'
  }
};
