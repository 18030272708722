import React from 'react';

const PlantIcon = ({ width = '50', height = '68', stroke = '#55603D', fill = 'none' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 50 68" fill={fill}>
      <path
        d="M34 34L44.5 34C50.0696 34 55.411 31.7875 59.3493 27.8492C63.2875 23.911 65.5 18.5695 65.5 13L65.5 9.5L55 9.5C49.4305 9.5 44.089 11.7125 40.1508 15.6508C36.2125 19.589 34 24.9305 34 30.5L23.5 30.5C17.9305 30.5 12.589 28.2875 8.65076 24.3492C4.71249 20.411 2.5 15.0695 2.5 9.5L2.5 2.5L13 2.5C18.5695 2.5 23.911 4.7125 27.8492 8.65076C31.7875 12.589 34 17.9305 34 23.5L34 44.5M16.5 44.5L51.5 44.5L51.5 58.5C51.5 60.3565 50.7625 62.137 49.4497 63.4497C48.137 64.7625 46.3565 65.5 44.5 65.5L23.5 65.5C21.6435 65.5 19.863 64.7625 18.5502 63.4497C17.2375 62.137 16.5 60.3565 16.5 58.5L16.5 44.5Z"
        stroke={stroke}
        strokeOpacity="0.4"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PlantIcon;
