import React from 'react';
import style from './styles.module.scss';

const ButtonWithIcon = ({ text, icon, onClick = () => null }) => {
  return (
    <button className={style.button} onClick={onClick}>
      <span className={style.buttonText}>{text}</span>
      <span className={style.buttonIcon}>{icon}</span>
    </button>
  );
};

export default ButtonWithIcon;
