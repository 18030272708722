import * as Yup from "yup";
export const CreateAdminShema = Yup.object().shape({
  email: Yup.string()
    .matches(
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email"
    )
    .required("Email is a required field"),
  password: Yup.string()
    .matches(
      /^(?=.*\d).{8,}$/,
      "Must contain 8 characters and at least one number"
    )
    .required("Password is a required field"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password confirmation is a required field"),
});
