import * as Yup from "yup";

export const SUPPORTED_OVER_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const checkImageFormat = (value) => {
  if (typeof value === "string") return true;

  return value ? SUPPORTED_OVER_FORMATS.includes(value.type) : true;
};

export const CreateHoroscopeSchema = Yup.object().shape({
  horoscopeCover: Yup.mixed()
    .test("fileFormat", "Unsupported Format", checkImageFormat)
    .test("filePresent", "Please choose file", (value) => !!value),
  horoscopeTitle: Yup.string().required("Title for horoscope is required"),
  horoscopeDescription: Yup.string().required(
    "Description for horoscope is required"
  ),

  tarotCover: Yup.mixed()
    .test("fileFormat", "Unsupported Format", checkImageFormat)
    .test("filePresent", "Please choose file", (value) => !!value),
  tarotText: Yup.string().required("Description for tarot is required"),
});
