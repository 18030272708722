import React from "react";
import style from "./styles.module.scss";
import { Button } from "../../../components";
import { fetchRequest } from "../../../API/fetchRequest";
import { useDispatch, useSelector } from "react-redux";
import { getAdmins } from "../../../redux/actions/admins";

const RightBlock = () => {
  const dispatch = useDispatch();
  const { admins } = useSelector((state) => state.adminsStore);

  const removeAdmin = async (id) => {
    const resp = await fetchRequest("DELETE", "admin", { id }, true);
    if (resp.success) {
      dispatch(getAdmins());
    }
  };

  return (
    <div className={style.rightBlock}>
      <div className={style.rightBlock__list}>
        {admins.map((item) => {
          return (
            <div key={item._id} className={style.rightBlock__listItem}>
              <span>{item.email}</span>
              <div className={style.rightBlock__listButton}>
                <Button text="Delete" onClick={() => removeAdmin(item._id)} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RightBlock;
