import React from 'react';
import style from './styles.module.scss';
import { Checkbox, TableItem } from '../../../components';

import Loader from '../../../components/Loader';

const TableBody = ({
  tableColumn,
  isUserTable,
  data = [],
  removeItem,
  loading,
  isCategoryTable,
  updateTable,
  onChangeOrder
}) => {
  return (
    <div className={style.tableBody}>
      <div className={style.tableBody__header}>
        {tableColumn.map((item) => {
          if (item.type === 'checkbox') {
            return (
              <div
                key={item.type}
                className={style.tableBody__headerItem}
                style={{ width: item.width }}
              >
                <Checkbox />
              </div>
            );
          } else {
            return (
              <div
                key={item.type}
                className={style.tableBody__headerItem}
                style={{ width: item.width }}
              >
                {item.title}
              </div>
            );
          }
        })}
      </div>

      <div className={style.tableBody__line} />
      {!loading && data.length === 0 && (
        <div className={style.tableBody__noResults}>NO RESULTS</div>
      )}

      {loading ? (
        <div className={style.tableBody__loader}>
          <Loader />
        </div>
      ) : (
        <div className={style.tableBody__items}>
          {data
            .sort((a, b) => {
              const dateA = new Date(a.publishDate || a.createdAt).getTime();
              const dateB = new Date(b.publishDate || b.createdAt).getTime();
              return dateB - dateA;
            })
            .map((item, index) => {
              return (
                <TableItem
                  index={index}
                  onChangeOrder={onChangeOrder}
                  updateTable={updateTable}
                  isCategoryTable={isCategoryTable}
                  removeItem={removeItem}
                  key={item._id}
                  isUserTable={isUserTable}
                  item={item}
                  tableColumn={tableColumn}
                  itemsLength={data.length}
                />
              );
            })}
          {/* <div className={style.tableBody__button}>
          <Button text="View more" />
        </div> */}
        </div>
      )}
    </div>
  );
};

export default TableBody;
