import { SET_HOROSCOPE_INFO } from "../actionTypes/horoscope";

const defaultState = {
  info: {
    dailyHoroscopeCover: "a6edc494-0f1a-4265-8c93-437f42e97d80",
    horoscopeCover: "f581f9d4-c5f8-43e7-9c93-6b518b273176",
    horoscopeDescription: "DESCRIPTION",
    tarotCover: "0548f752-5202-40fb-983a-5afc7e30a503",
    tarotText: "TAROT TEXT",
    horoscopeTitle: "n",
  },
};

const horoscope = (state = defaultState, action) => {
  switch (action.type) {
    case SET_HOROSCOPE_INFO: {
      return {
        ...state,
        info: action.payload,
      };
    }

    default:
      return state;
  }
};
export default horoscope;
