import { createSlice } from '@reduxjs/toolkit';
import {
  createPromptThunks,
  createSearchRequestThunks,
  deleteMessageThunks,
  deletePromptsThunks,
  deletePromptThunks,
  getMessagesThunks,
  getPromptsThunks,
  getPromptThunks,
  lazyLoadMessagesThunks,
  lazyLoadPromptsThunks,
  updatePromptThunks
} from '../../thunks/prompt';

const initialState = {
  prompt: {},
  prompts: [],
  messages: [],
  answersCount: null,
  page: 1,
  totalPages: null,
  promptPage: null,
  promptTotalPages: null,
  isLoading: false,
  isError: false
};

export const promptSlice = createSlice({
  name: 'prompt',
  initialState,

  reducers: {
    addMessageToChat: (state, action) => {
      state.messages.unshift(action.payload);
    },

    clearMessages: (state) => {
      state.messages = [];
    }
  },

  extraReducers: (builder) => {
    builder.addCase(createPromptThunks.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(createPromptThunks.fulfilled, (state, action) => {
      const existingPromptIndex = state.prompts.findIndex(
        (prompt) => prompt._id === action.payload.prompt._id
      );

      if (existingPromptIndex !== -1) {
        state.prompts[existingPromptIndex] = action.payload.prompt;
      } else {
        if (state.prompts.length < 10) {
          state.prompts = [...state.prompts, action.payload.prompt];
        }
      }

      state.totalPages = action.payload.totalPages;
      state.isLoading = false;
    });

    builder.addCase(createPromptThunks.rejected, (state) => {
      state.isError = true;
      state.isLoading = false;
    });

    builder.addCase(updatePromptThunks.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(updatePromptThunks.fulfilled, (state, action) => {
      const updatedPrompt = action.payload.prompt;

      state.prompts = state.prompts.map((prompt) =>
        prompt._id === updatedPrompt._id ? updatedPrompt : prompt
      );
      state.totalPages = action.payload.totalPages;
      state.isLoading = false;
    });

    builder.addCase(updatePromptThunks.rejected, (state) => {
      state.isError = true;
      state.isLoading = false;
    });

    builder.addCase(deletePromptsThunks.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(deletePromptsThunks.fulfilled, (state, action) => {
      state.prompts = state.prompts.filter(
        (prompt) => !action.payload.promptIds.includes(prompt._id)
      );
      state.loading = false;
    });

    builder.addCase(deletePromptsThunks.rejected, (state, action) => {
      state.loading = false;
      state.isError = true;
    });

    builder.addCase(getPromptThunks.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getPromptThunks.fulfilled, (state, action) => {
      state.prompt = action.payload.prompt;
      state.isLoading = false;
    });

    builder.addCase(getPromptThunks.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    builder.addCase(getPromptsThunks.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getPromptsThunks.fulfilled, (state, action) => {
      state.prompts = action.payload.prompts;
      state.promptPage = action.payload.currentPage;
      state.promptTotalPages = action.payload.totalPages;
      state.isLoading = false;
    });

    builder.addCase(getPromptsThunks.rejected, (state) => {
      state.isError = true;
      state.isLoading = false;
    });

    builder.addCase(lazyLoadPromptsThunks.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(lazyLoadPromptsThunks.fulfilled, (state, action) => {
      state.prompts = [...state.prompts, ...action.payload.prompts];
      state.promptPage = action.payload.currentPage;
      state.promptTotalPages = action.payload.totalPages;
      state.isLoading = false;
    });

    builder.addCase(lazyLoadPromptsThunks.rejected, (state) => {
      state.isError = true;
      state.isLoading = false;
    });

    builder.addCase(getMessagesThunks.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getMessagesThunks.fulfilled, (state, action) => {
      state.messages = action.payload.messages;
      state.answersCount = action.payload.answers;
      state.page = action.payload.page;
      state.totalPages = action.payload.totalPages;
      state.isLoading = false;
    });

    builder.addCase(getMessagesThunks.rejected, (state) => {
      state.isError = true;
      state.isLoading = false;
    });

    builder.addCase(lazyLoadMessagesThunks.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(lazyLoadMessagesThunks.fulfilled, (state, action) => {
      state.messages = [...state.messages, ...action.payload.messages];
      state.answersCount = action.payload.answers;
      state.page = action.payload.page;
      state.totalPages = action.payload.totalPages;
      state.isLoading = false;
    });

    builder.addCase(lazyLoadMessagesThunks.rejected, (state) => {
      state.isError = true;
      state.isLoading = false;
    });

    builder.addCase(createSearchRequestThunks.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(createSearchRequestThunks.fulfilled, (state, action) => {
      state.messages = action.payload.messages;
      state.page = action.payload.page;
      state.totalPages = action.payload.totalPages;
      state.isLoading = false;
    });

    builder.addCase(createSearchRequestThunks.rejected, (state) => {
      state.isError = true;
      state.isLoading = false;
    });

    builder.addCase(deleteMessageThunks.fulfilled, (state, action) => {
      state.messages = state.messages.filter((message) => message._id !== action.payload.messageId);
    });

    builder.addCase(deleteMessageThunks.rejected, (state) => {
      state.isError = true;
    });

    builder.addCase(deletePromptThunks.fulfilled, (state, action) => {
      state.prompts = state.prompts.filter((prompt) => prompt._id !== action.payload.promptId);
    });

    builder.addCase(deletePromptThunks.rejected, (state) => {
      state.isError = true;
    });
  }
});

export const { addMessageToChat, clearMessages } = promptSlice.actions;
export default promptSlice.reducer;
