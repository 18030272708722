import * as Yup from "yup";
export const FILE_SIZE = 1024 * 1024;
export const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
export const CreateCategoryShema = Yup.object().shape({
  title: Yup.string().required("Name for category is a required field"),
  page: Yup.string().required("Category place is a required field"),
  cover: Yup.mixed()
    .test("fileFormat", "Unsupported Format", (value) =>
      value ? SUPPORTED_FORMATS.includes(value.type) : true
    )
    .test("filePresent", "Please choose file", (value) => !!value)

    .nullable(),
});
