import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./styles.module.scss";

import { DropZone, Input, Button, Loader } from "../../components";
import { CreateHoroscopeSchema } from "../../utils/validations/CreateHoroscopeSchema";

import {
  setHoroscopeInfo,
  getHoroscopeInfo,
} from "../../redux/actions/horoscope";

import { fetchFormDataRequest } from "../../API/fetchFormDataRequest";
import { BUCKET } from "../../constants/api";

const Horoscope = () => {
  const [isRequesting, setIsRequesting] = useState(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      horoscopeCover: null,
      horoscopeTitle: "",
      horoscopeDescription: "",
      dailyHoroscopeCover: null,
      tarotCover: null,
      tarotText: "",
      date: moment(),
    },
    validationSchema: CreateHoroscopeSchema,
    onSubmit: updatedHoroscopInfo,
  });
  const { values, handleSubmit, setFieldValue, touched, errors, resetForm } =
    formik;

  const fetchData = () => {
    dispatch(getHoroscopeInfo());
  };

  useEffect(() => {
    fetchData();
  }, []);

  async function updatedHoroscopInfo(values) {
    setIsRequesting(true);
    const { dailyHoroscopeCover, horoscopeCover, tarotCover, date, ...rest } =
      values;

    const fd = new FormData();

    if (typeof horoscopeCover !== "string") {
      fd.append("horoscopeCover", horoscopeCover);
    }
    if (typeof tarotCover !== "string") {
      fd.append("tarotCover", tarotCover);
    }

    const mDate = date.utcOffset(0);
    mDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    debugger;
    fd.append("date", mDate.toISOString());

    for (let key in rest) {
      fd.append(key, rest[key]);
    }

    const { taroUpdated } = await fetchFormDataRequest(
      "POST",
      "admin/taro",
      fd,
      true
    );

    dispatch(setHoroscopeInfo(taroUpdated));
    setIsRequesting(false);
    resetForm();
  }

  const onChange = (value, name) => {
    formik.setFieldValue(name, value);
  };

  const saveCover = (file, fieldName) => {
    if (file[0].type === "image/png" || file[0].type === "image/jpeg") {
      setFieldValue(fieldName, file[0]);
    }
  };

  const renderImage = (img) => {
    if (typeof img === "string") {
      return `${BUCKET}/${img}`;
    }
    return img;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper__block}>
        <div className={styles.wrapper__block_section}>
          <h3>Moon horoscope</h3>
          <div className={styles.wrapper__block_section_field}>
            <DropZone
              setFile={(file) => saveCover(file, "horoscopeCover")}
              file={renderImage(values.horoscopeCover)}
              removeFile={() => setFieldValue("horoscopeCover", null)}
              error={touched.horoscopeCover && errors.horoscopeCover}
            />
          </div>
          <div className={styles.wrapper__block_section_field}>
            <Input
              label="Title for horoscope"
              formik={formik}
              name="horoscopeTitle"
              onChange={onChange}
              error={touched.horoscopeTitle && errors.horoscopeTitle}
            />
          </div>
          <div className={styles.wrapper__block_section_field}>
            <Input
              label="Description for horoscope"
              formik={formik}
              name="horoscopeDescription"
              onChange={onChange}
              error={
                touched.horoscopeDescription && errors.horoscopeDescription
              }
            />
          </div>
        </div>
        {/* 
        <div className={styles.wrapper__block_section}>
          <h3>Daily horoscope</h3>
          <div className={styles.wrapper__block_section_field}>
            <DropZone
              setFile={(file) => saveCover(file, "dailyHoroscopeCover")}
              file={renderImage(values.dailyHoroscopeCover)}
              removeFile={() => setFieldValue("dailyHoroscopeCover", null)}
              error={touched.dailyHoroscopeCover && errors.dailyHoroscopeCover}
            />
          </div>
        </div> */}

        <div className={styles.wrapper__block_section}>
          <h3>Tarote horoscope</h3>
          <div className={styles.wrapper__block_section_field}>
            <DropZone
              setFile={(file) => saveCover(file, "tarotCover")}
              file={renderImage(values.tarotCover)}
              removeFile={() => setFieldValue("tarotCover", null)}
              error={touched.tarotCover && errors.tarotCover}
            />
          </div>
          <div className={styles.wrapper__block_section_field}>
            <Input
              label="Title for tarote horoscope"
              formik={formik}
              name="tarotText"
              onChange={onChange}
              error={touched.tarotText && errors.tarotText}
            />
          </div>
        </div>

        <div className={styles.wrapper__block_section}>
          <h3>Publicate</h3>
          <DatePicker
            selected={values.date.toDate()}
            minDate={moment().toDate()}
            onChange={(date) => setFieldValue("date", moment(date))}
          />
        </div>

        <div className={styles.wrapper__button}>
          <Button type="submit" text="Save" onClick={handleSubmit} />
        </div>
      </div>

      {isRequesting && (
        <div className={styles.wrapper__loader}>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default Horoscope;
