import * as Yup from "yup";
export const FILE_SIZE = 1024 * 1024;
export const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
export const EditPostShems = Yup.object().shape({
  page: Yup.string().required("Page is required"),
  category: Yup.string().when("type", {
    is: "Story",
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required("Category is required"),
  }),
  type: Yup.string().required("Type is required"),
  cover: Yup.mixed()
    .test("fileSize", "File too large", (value) =>
      value ? value.size <= FILE_SIZE * 10 : true
    )
    .test("fileFormat", "Unsupported Format", (value) =>
      value ? SUPPORTED_FORMATS.includes(value.type) : true
    )
    .nullable(),
  title: Yup.string().when("type", {
    is: "Story",
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required("Title is required"),
  }),
  description: Yup.string().when("type", {
    is: "Story",
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required("Description is required"),
  }),
});
