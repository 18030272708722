import React, { useEffect, useState } from "react";
import { DropZone, Button, Input, TextArea, Select, Modal, Checkbox } from "..";
import { fetchFormDataRequest } from "../../API/fetchFormDataRequest";
import style from "./styles.module.scss";
import { EditCategoryShema } from "../../utils/validations/EditCategoryShema";
import { useFormik } from "formik";
import { pages } from "../../constants/selectArrays";
import { SUPPORTED_COVER_FORMATS } from "../../constants/supportedCoverFormats";
const CategoryItemEdit = ({ item, updateTable }) => {
  const [showModal, setShowModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      page: "",
      type: "",
      cover: null,
      title: "",
      subtitle: "",
      description: "",
      main: typeof item.main !== "undefined" ? item.main : false,
    },
    validationSchema: EditCategoryShema,
    onSubmit: async (values, { resetForm }) => {
      setShowModal(true);
    },
  });

  const saveCover = (file) => {
    if (SUPPORTED_COVER_FORMATS.find((item) => item === file[0].type)) {
      onChange(file[0], "cover");
    }
  };

  const onChange = (value, name) => {
    formik.setFieldValue(name, value);
  };

  const onSubmit = async () => {
    const values = formik.values;
    const formData = new FormData();
    Object.keys(values).forEach((item) => {
      formData.append(item, values[item]);
    });

    formData.append("categoryId", item._id);

    const resp = await fetchFormDataRequest(
      "PUT",
      "admin/category",
      formData,
      true
    );
    setShowModal(false);
    if (resp && resp.success) {
      updateTable && updateTable();
      formik.resetForm();
    }
  };

  const setDefaultData = () => {
    formik.setFieldValue("title", item.title);
    formik.setFieldValue("description", item.description);
    formik.setFieldValue("page", item.page);
    formik.setFieldValue("subtitle", item.subtitle);
  };

  useEffect(() => {
    setDefaultData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={style.itemEdit}>
      {showModal && (
        <Modal
          text="Are you sure you want to save the Settings?"
          onSubmit={onSubmit}
          onClose={() => setShowModal(false)}
        />
      )}
      <div className={style.itemEdit__left}>
        <div className={style.itemEdit__leftDropZone}>
          <DropZone
            file={formik.values.cover}
            setFile={saveCover}
            removeFile={() => onChange(null, "cover")}
          />
        </div>

        <div className={style.itemEdit__block}>
          <Input
            label="Name for Category*"
            formik={formik}
            onChange={onChange}
            name="title"
            error={formik.touched.title && formik.errors.title}
          />
        </div>
        {!item.main && item.page === "Meditate" && (
          <div className={style.itemEdit__block}>
            <Checkbox
              value={formik.values.main}
              onClick={() => onChange(!formik.values.main, "main")}
              name="main"
            />
            <p className={style.itemEdit__checkboxTitle}>Is feature</p>
          </div>
        )}
        <div className={style.itemEdit__block}>
          <Input
            label="Subtitle name"
            formik={formik}
            onChange={onChange}
            name="subtitle"
          />
        </div>
      </div>
      <div className={style.itemEdit__right}>
        <div className={style.itemEdit__textarea}>
          <TextArea
            formik={formik}
            onChange={onChange}
            name="description"
            label="Post Description"
          />
        </div>
        <div className={style.itemEdit__block}>
          <Select
            label="Category place"
            placeholder="Choose"
            formik={formik}
            name="page"
            data={pages}
            onChange={onChange}
          />
        </div>
        <div className={style.itemEdit__button}>
          <Button text="Save changes" onClick={() => formik.submitForm()} />
        </div>
      </div>
    </div>
  );
};

export default CategoryItemEdit;
