import { SET_POSTS, CLEAR_POSTS } from "../actionTypes/posts";

const defaultState = {
  posts: [],
};

const user = (state = defaultState, action) => {
  switch (action.type) {
    case SET_POSTS: {
      return {
        ...state,
        posts: [...action.payload],
      };
    }
    case CLEAR_POSTS: {
      return { ...state, posts: [] };
    }

    default:
      return state;
  }
};
export default user;
