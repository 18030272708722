import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import styles from './styles.module.scss';

import { Button } from '../../../components';

import { Box } from '../../../assets/svg';

import { fetchRequest } from '../../../API/fetchRequest';
import { BUCKET } from '../../../constants/api';

export const MantrasList = forwardRef((props, ref) => {
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [isLastPosts, setIsLastPosts] = useState(false);

  const fetchData = async () => {
    const { mantras, success } = await fetchRequest(
      'GET',
      `admin/mantras?page=${page}`,
      undefined,
      true
    );

    if (success) {
      setList([...list, ...mantras]);

      if (mantras.length < 15) setIsLastPosts(true);
    } else {
      alert('Something went wrong');
    }
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  useImperativeHandle(ref, () => ({
    addMantra(mantra) {
      setList([mantra, ...list]);
    }
  }));

  const loadMore = () => {
    setPage(page + 1);
  };

  const handleDeleteMantra = async (id) => {
    const { success } = await fetchRequest('DELETE', 'admin/mantra', { id }, true);

    if (success) {
      const updatedItems = list.filter((i) => i._id !== id);
      setList(updatedItems);
    } else {
      alert('Something went wrong');
    }
  };

  const renderItems = () => {
    return list.map((item) => (
      <div key={item._id} className={styles.wrapper__item}>
        <div
          className={styles.wrapper__item_photo}
          style={{
            backgroundImage: `url(${BUCKET}/${item.cover})`
          }}
        />
        <div className={styles.wrapper__item_delete} onClick={() => handleDeleteMantra(item._id)}>
          <Box />
        </div>
      </div>
    ));
  };

  return (
    <div className={styles.wrapper}>
      <h3>Mantras List</h3>

      <div>{renderItems()}</div>

      {!isLastPosts && (
        <div className={styles.wrapper__buttonContainer}>
          <Button text="Load more" onClick={loadMore} />
        </div>
      )}

      {isLastPosts && list.length === 0 && (
        <p className={styles.wrapper__noItems}>Don't have mantras yet</p>
      )}
    </div>
  );
});
