import React, { useEffect } from "react";
import style from "./styles.module.scss";
import CentralBlock from "../../blocks/settings/centralBlock";
import RightBlock from "../../blocks/settings/rightBlock";
import { useDispatch } from "react-redux";
import { getAdmins } from "../../redux/actions/admins";

const SettingsPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdmins());
    // eslint-disable-next-line
  }, []);

  return (
    <div className={style.settingsBlock}>
      <CentralBlock />
      <RightBlock />
    </div>
  );
};

export default SettingsPage;
