import React, { useState } from "react";
import style from "./styles.module.scss";
import {
  DropZone,
  Input,
  TextArea,
  Button,
  Select,
  Modal,
  Checkbox,
} from "../../../components";
import { useFormik } from "formik";
import { pages } from "../../../constants/selectArrays";
import { CreateCategoryShema } from "../../../utils/validations/CreateCategoryShema";
import { fetchFormDataRequest } from "../../../API/fetchFormDataRequest";
const RightBlock = () => {
  const [showModal, setShowModal] = useState(false);
  const formik = useFormik({
    initialValues: {
      title: "",
      subtitle: "",
      page: "",
      description: "",
      cover: null,
      main: false,
    },
    validationSchema: CreateCategoryShema,
    onSubmit: async (values, { resetForm }) => {
      setShowModal(true);
    },
  });

  const onChange = (value, name) => {
    formik.setFieldValue(name, value);
  };

  const onSubmit = async () => {
    const values = formik.values;
    const formData = new FormData();
    Object.keys(values).forEach((item) => {
      formData.append(item, values[item]);
    });

    const resp = await fetchFormDataRequest(
      "POST",
      "admin/category",
      formData,
      true
    );
    setShowModal(false);
    if (resp.success) {
      formik.resetForm();
    }
  };

  const saveCover = (file) => {
    if (file[0].type === "image/png" || file[0].type === "image/jpeg") {
      formik.setFieldValue("cover", file[0]);
    }
  };

  return (
    <div className={style.rightBlock}>
      {showModal && (
        <Modal
          text="Are you sure you want to save the Settings?"
          onClose={() => setShowModal(false)}
          onSubmit={onSubmit}
        />
      )}
      <div className={style.rightBlock__dropzone}>
        <DropZone
          setFile={saveCover}
          file={formik.values.cover}
          removeFile={() => formik.setFieldValue("cover", null)}
          error={formik.touched.cover && formik.errors.cover}
        />
      </div>
      <Input
        label="Create name for Category*"
        formik={formik}
        name="title"
        onChange={onChange}
        error={formik.touched.title && formik.errors.title}
      />
      <div className={style.rightBlock__field}>
        <Input
          label="Create Subtitle name"
          formik={formik}
          name="subtitle"
          onChange={onChange}
        />
      </div>
      <div className={style.rightBlock__field}>
        <Select
          label="Choose Category place*"
          data={[pages[1], pages[2]]}
          formik={formik}
          name="page"
          onChange={onChange}
          error={formik.touched.page && formik.errors.page}
        />
      </div>
      {formik.values.page === pages[1] && (
        <div className={style.rightBlock__field}>
          <Checkbox
            value={formik.values.main}
            onClick={() => onChange(!formik.values.main, "main")}
          />
          <div className={style.rightBlock__checkboxTitle}>
            Is feature category
          </div>
        </div>
      )}
      <div className={style.rightBlock__field}>
        <TextArea
          label="Post Discription"
          formik={formik}
          name="description"
          onChange={onChange}
        />
      </div>
      <div className={style.rightBlock__button}>
        <Button text="Save and publish" onClick={() => formik.submitForm()} />
      </div>
    </div>
  );
};

export default RightBlock;
