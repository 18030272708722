import React, { useState, useRef } from "react";
import style from "./styles.module.scss";
import clsx from "clsx";
import { getNewValue } from "../../utils/time";
import { useOutsideClick } from "../../hooks/useOutsideClick";
const TimePicker = ({
  label,
  error,
  value,
  onChange,
  onChangeTimeOfDay,
  timeOfDay,
  placeholder,
  mistake = false,
  formik,
  name,
  timeName,
  isDisabled,
}) => {
  const [isOpen, setOpen] = useState(false);
  const popupRef = useRef();
  useOutsideClick({ ref: popupRef, callback: () => setOpen(false) });

  const [isFocused, setFocused] = useState(false);
  const onFocus = () => {
    setFocused(true);
  };
  const onBlur = () => {
    setFocused(false);
  };
  const onChangeInput = (e) => {
    if (isDisabled) return;
    let choosedVal = formik ? formik.values.time : value;
    const { isCorrect, val } = getNewValue(choosedVal, e.target.value);
    if (isCorrect) {
      onChange(val, name);
    }
  };

  return (
    <div className={style.inputBlock}>
      <div
        className={clsx(
          style.inputBlock__label,
          { [style.focus]: isFocused },
          { [style.error]: !!error }
        )}
      >
        {label}
      </div>

      <div
        className={clsx(
          style.inputWrapper,
          { [style.focus]: isFocused },
          { [style.error]: !!error }
        )}
      >
        <input
          type="text"
          value={formik ? formik.values.time : value}
          onChange={onChangeInput}
          placeholder="--:--"
          className={clsx(style.input)}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={isDisabled}
        />
        <div
          ref={popupRef}
          onClick={() => {
            if (isDisabled) return;
            setOpen(!isOpen);
          }}
          className={style.popup}
        >
          {formik ? formik.values.timeOfDay : timeOfDay}
          <div
            className={clsx(style.list__wrapper, { [style.active]: isOpen })}
            // onClick={(e) => e.stopPropagation()}
          >
            <div className={clsx(style.list)}>
              <div
                onClick={() => {
                  onChange("am", timeName);
                  // onChangeTimeOfDay("am");
                }}
                className={style.list__item}
              >
                am
              </div>
              <div
                onClick={() => {
                  // onChangeTimeOfDay("pm");
                  onChange("pm", timeName);
                }}
                className={style.list__item}
              >
                pm
              </div>
            </div>
          </div>
        </div>
      </div>

      {!!error && <div className={style.inputBlock__error}>{error}</div>}
    </div>
  );
};

export default TimePicker;
