import React, { useEffect, useRef, useState } from 'react';
import style from './styles.module.scss';
import { icons } from '../../../assets/png';
import PlusIcon from '../../../assets/svg/plus';
import Checkbox from '../../../components/Checkbox';
import TrashIcon from '../../../assets/svg/trash';
import PlantIcon from '../../../assets/svg/plant';
import ButtonWithIcon from '../../../components/ButtonWithIcon';
import HorizontalDotsIcon from '../../../assets/svg/horizontalDots';
import { useDispatch, useSelector } from 'react-redux';
import {
  createPromptThunks,
  deletePromptsThunks,
  deletePromptThunks,
  getMessagesThunks,
  getPromptsThunks,
  getPromptThunks,
  lazyLoadPromptsThunks,
  updatePromptThunks
} from '../../../redux/thunks/prompt';
import useOutsideClick from '../../../hooks/outside-click';
import AppModalWindow from '../../../components/AppModalWindow';
import CreatePromptComponent from '../create-prompt';
import EditIcon from '../../../assets/svg/edit';
import moment from 'moment';
import DeletePromptsComponent from '../delete-prompts';
import { useSocket } from '../../../hooks/socket';
import { clearMessages } from '../../../redux/slices/prompt';

const PromptComponent = () => {
  const [openMenuId, setOpenMenuId] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [date, setDate] = useState(new Date());
  const [promptText, setPromptText] = useState('');
  const [checkedIds, setCheckedIds] = useState([]);
  const [actionType, setActionType] = useState('create');
  const menuRef = useRef(null);
  const dispatch = useDispatch();
  const socket = useSocket();
  const { prompts, promptPage, promptTotalPages, isLoading } = useSelector(
    (state) => state.promptStore
  );
  const promptRecordsBlockRef = useRef(null);

  useEffect(() => {
    dispatch(getPromptsThunks({ page: 1 }));
  }, [dispatch, socket]);

  useEffect(() => {
    const handleScroll = async () => {
      if (promptRecordsBlockRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = promptRecordsBlockRef.current;
        if (
          scrollTop + clientHeight >= scrollHeight - 200 &&
          promptPage < promptTotalPages &&
          !isLoading
        ) {
          await dispatch(lazyLoadPromptsThunks({ page: promptPage + 1 }));
        }
      }
    };

    const promptRecordsBlock = promptRecordsBlockRef.current;
    if (promptRecordsBlock) {
      promptRecordsBlock.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (promptRecordsBlock) {
        promptRecordsBlock.removeEventListener('scroll', handleScroll);
      }
    };
  }, [dispatch, promptPage, promptTotalPages, isLoading]);

  const handleSubmit = () => {
    dispatch(
      actionType === 'create'
        ? createPromptThunks({
            message: promptText,
            publishDate: moment(date).format('YYYY-MM-DD')
          })
        : updatePromptThunks({
            message: promptText,
            publishDate: date,
            promptId: checkedIds[0]
          })
    )
      .unwrap()
      .then(() => dispatch(getPromptThunks()));
    setIsOpen(false);
    setPromptText('');
    setDate(new Date());
  };

  const handleCheck = (id) => {
    setCheckedIds((prevIds) =>
      prevIds.includes(id) ? prevIds.filter((prevId) => prevId !== id) : [...prevIds, id]
    );
  };

  const handleCreateRecord = () => {
    setIsOpen(true);
    setActionType('create');
  };

  const handleUpdateRecord = (prompt) => {
    setIsOpen(true);
    setActionType('update');
    setOpenMenuId(null);
    setDate(prompt.publishDate);
    setPromptText(prompt.message);
    setCheckedIds([prompt._id]);
  };

  const handleDeletePrompt = (promptId) => {
    dispatch(deletePromptThunks({ promptId }))
      .unwrap()
      .then(() => dispatch(getPromptThunks()));
    socket.on('deletePromptData', (deletedPromptId) => {
      if (promptId === deletedPromptId) {
        dispatch(getMessagesThunks({ page: 1 }))
          .unwrap()
          .catch((error) => {
            if (error.statusCode === 404) {
              dispatch(clearMessages());
            }
          });
      }
    });
  };

  const handleDeleteManyRecords = () => {
    if (checkedIds.length > 0) {
      setActionType('delete');
      setIsOpen(true);
    }
  };

  const handleManyDeletePrompt = () => {
    if (checkedIds.length > 0) {
      dispatch(deletePromptsThunks({ promptIds: checkedIds }))
        .unwrap()
        .then(() => {
          dispatch(getPromptThunks());
          dispatch(getMessagesThunks({ page: 1 }));
        });
      setIsOpen(false);
      setCheckedIds([]);
    }
  };

  const handleMenuToggle = (messageId) => {
    setOpenMenuId((prevId) => (prevId === messageId ? null : messageId));
  };

  const closeModal = () => {
    setIsOpen(false);
    setActionType('create');
    setDate(new Date());
    setPromptText('');
    if (actionType === 'delete') {
      setCheckedIds([]);
    }
  };

  useOutsideClick(menuRef, () => setOpenMenuId(null));

  return (
    <div className={style.promptPageWrapper}>
      <div className={style.headerImage}>
        <img src={String(icons.promptImage)} alt="Prompt header" />
      </div>
      <div className={style.promptBlockWrapper}>
        <div className={style.promptHeader}>
          <h2>Scheduled prompts</h2>
          <div className={style.addButtonIcon} onClick={handleCreateRecord}>
            <PlusIcon />
          </div>
        </div>

        <div className={style.promptManageBlock}>
          <div className={style.promptCheckBoxWrapper}>
            <Checkbox
              value={checkedIds.length === prompts.length}
              onClick={() =>
                setCheckedIds(
                  checkedIds.length === prompts.length ? [] : prompts.map(({ _id }) => _id)
                )
              }
            />
            <p>Select all</p>
          </div>

          <div
            className={checkedIds.length > 0 ? style.readyToDelete : style.notReadyToDelete}
            onClick={handleDeleteManyRecords}
          >
            <TrashIcon
              width="24px"
              height="24px"
              stroke={`${checkedIds.length > 0 ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.3)'}`}
            />
          </div>
        </div>

        <div className={style.promptRecordsBlock} ref={promptRecordsBlockRef}>
          {!prompts.length && (
            <div className={style.promptRecordsEmptyBlock}>
              <p>There are no entries here yet.</p>
            </div>
          )}
          {prompts &&
            [...prompts]
              .sort((a, b) => new Date(a.publishDate) - new Date(b.publishDate))
              .map((prompt) => (
                <div className={style.promptRecordItem} key={prompt._id}>
                  <Checkbox
                    value={checkedIds.includes(prompt._id)}
                    onClick={() => handleCheck(prompt._id)}
                  />
                  <div className={style.promptContentWrapper}>
                    <div className={style.promptContent}>
                      <div className={style.contentDate}>
                        {moment.utc(prompt.publishDate).format('ddd D MMM, h:mm A')}
                      </div>
                      <div
                        className={style.dotsIconWrapper}
                        onClick={() => handleMenuToggle(prompt._id)}
                      >
                        <HorizontalDotsIcon />
                      </div>

                      {openMenuId === prompt._id && (
                        <div className={style.subMenuWrapper} ref={menuRef}>
                          <ul>
                            <li onClick={() => handleUpdateRecord(prompt)}>
                              <EditIcon />
                              <p>Edit</p>
                            </li>

                            <li onClick={() => handleDeletePrompt(prompt._id)}>
                              <TrashIcon />
                              <p className={style.red}>Delete</p>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                    <div className={style.content}>{prompt.message}</div>
                  </div>
                </div>
              ))}

          <div className={style.promptBottomBlock}>
            <div className={style.promptBottomAction}>
              <div className={style.promptBottomActionText}>
                <p>You haven’t created a prompt for the other days. Do you want to create one?</p>
              </div>
              <div>
                <ButtonWithIcon
                  text="Add a prompt"
                  icon={<PlusIcon stroke="#55603D" />}
                  onClick={handleCreateRecord}
                />
              </div>
            </div>
            <div className={style.plantIcon}>
              <PlantIcon stroke="#fff" height="178px" width="118px" />
            </div>
          </div>
        </div>
      </div>

      <AppModalWindow
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        initialPrompt="What is one thing you can do today to make someone else's day better?"
      >
        {actionType === 'create' || actionType === 'update' ? (
          <CreatePromptComponent
            label="Schedule daily prompt"
            date={date}
            handleClose={closeModal}
            setPromptText={setPromptText}
            handleSubmit={handleSubmit}
            promptText={promptText}
            setDate={setDate}
          />
        ) : (
          <DeletePromptsComponent handleDelete={handleManyDeletePrompt} handleClose={closeModal} />
        )}
      </AppModalWindow>
    </div>
  );
};

export default PromptComponent;
