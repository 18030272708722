import React from "react";
import style from "./styles.module.scss";
import { NotificationItem } from "../../../components";

const RightBlock = ({ notifications }) => {
  return (
    <div className={style.rightBlock}>
      <div className={style.rightBlock__title}>Notification send</div>

      <div className={style.rightBlock__list}>
        {notifications.map((item) => {
          return <NotificationItem item={item} />;
        })}
      </div>
    </div>
  );
};

export default RightBlock;
