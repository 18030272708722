import React, { useState } from "react";
import style from "./styles.module.scss";
import { Input, Button, Switch, SuccessModal } from "../../../components";
import { fetchRequest } from "../../../API/fetchRequest";
import { useFormik } from "formik";
import { CreateBannerShema } from "../../../utils/validations/CreateBannerShema";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

const CentralBlock = () => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      isShow: false,
    },
    validationSchema: CreateBannerShema,
    onSubmit: async (values) => {
      const resp = await fetchRequest(
        "PUT",
        "admin/banner",
        {
          title: values.title,
          description: values.description,
          isShow: values.isShow,
        },
        true
      );

      resp?.success && setShowModal(true);
    },
  });

  const onChange = (value, name) => {
    formik.setFieldValue(name, value);
  };

  const changeBannerStatus = () => {
    formik.setFieldValue("isShow", !formik.values.isShow);
  };

  const onSubmit = () => {
    formik.submitForm();
  };

  const getData = async () => {
    const resp = await fetchRequest("GET", "admin/banner", undefined, true);

    if (resp.success) {
      formik.setFieldValue("title", resp.banner.title);
      formik.setFieldValue("description", resp.banner.description);
      formik.setFieldValue("isShow", resp.banner.isShow);
    }
    console.log("getData ==> resp", resp);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={style.centralBlock}>
      <div className={style.centralBlock__title}>Edit banner</div>
      <div className={style.centralBlock__field}>
        <Input
          placeholder={"Text"}
          formik={formik}
          name="title"
          onChange={onChange}
          label="Enter title"
          error={formik.touched.title && formik.errors.title}
        />
      </div>
      <div className={style.centralBlock__field}>
        <Input
          placeholder={"Text"}
          formik={formik}
          name="description"
          onChange={onChange}
          label="Enter description"
          error={formik.touched.description && formik.errors.description}
        />
      </div>

      <div className={style.centralBlock_switcher}>
        <Switch value={formik.values.isShow} onClick={changeBannerStatus} />
        <div className={style.centralBlock_switcherText}>
          Display banner in the app
        </div>
      </div>

      <div className={style.centralBlock__button}>
        <Button text="Confirm" onClick={onSubmit} />
      </div>

      {showModal && (
        <SuccessModal
          text={"Banner updated successfully"}
          onClose={() => setShowModal(false)}
        />
      )}

      {/* {showModal && (
        <Modal
          text="Are you sure you want to save the Settings?"
          onClose={() => setShowModal(false)}
          onSubmit={onSubmit}
        />
      )} */}
    </div>
  );
};

export default CentralBlock;
