import { API } from '../constants/api';

import { getToken } from '../utils/tokenLS';

export const fetchFormDataRequest = async (method = 'POST', query, body, withAuth) => {
  let token = '';
  let myHeaders = new Headers();

  try {
    if (withAuth) {
      token = getToken();
      myHeaders.append('Authorization', `Bearer ${token}`);
    }
    const response = await fetch(`${API}/${query}`, {
      method: method,
      headers: myHeaders,
      body: body
    });

    if (response.status === 200 || response.status === 201) {
      const data = await response.json();
      return data;
    } else if (response.status === 400) {
      const data = await response.json();
      return data;
    } else if (response.status === 403) {
      return false;
    } else if (response.status === 500) {
      console.error('Sorry the server is not available right now please try later');
    }
  } catch (error) {
    console.log('backend', error);
  }
};
