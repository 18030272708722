import React, { useEffect } from 'react';
import style from './styles.module.scss';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getCategories } from '../../../redux/actions/categories';
import {
  CreateAndManage,
  CreatePost,
  Notification,
  PostManage,
  Settings
} from '../../../assets/svg';
import { icons } from '../../../assets/png';
import { getCurrentAdmin } from '../../../redux/actions/admins';
import { removeToken } from '../../../utils/tokenLS';
import { getSuperAdmin, removeSuperAdmin } from '../../../utils/superAdminLS';

const LeftPanel = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isSuperAdmin = getSuperAdmin();

  const logOut = () => {
    removeToken();
    removeSuperAdmin();

    history.push('/');
  };

  useEffect(() => {
    dispatch(getCurrentAdmin());
    dispatch(getCategories());
    // eslint-disable-next-line
  }, []);

  return (
    <div className={style.leftPanel}>
      <div className={style.leftPanel__title}>Wild Minds</div>
      <div className={style.leftPanel__subtitle}>Admin tools</div>
      <div className={style.leftPanel__menu}>
        <NavLink activeClassName={style.active} to="/createPost">
          <PostManage />
          Create a post
        </NavLink>
        <NavLink activeClassName={style.active} to="/banner">
          <PostManage />
          Banner
        </NavLink>
        <NavLink activeClassName={style.active} to="/prompt">
          <PostManage />
          Prompt
        </NavLink>
        <NavLink activeClassName={style.active} to="/postManagement">
          <PostManage />
          Post management
        </NavLink>
        <NavLink activeClassName={style.active} to="/createAndManage">
          <CreateAndManage />
          Categories
        </NavLink>
        <NavLink activeClassName={style.active} to="/usersManagment">
          <CreatePost />
          Users managment
        </NavLink>
        {isSuperAdmin && (
          <NavLink activeClassName={style.active} to="/settings">
            <Settings />
            Settings
          </NavLink>
        )}

        <NavLink activeClassName={style.active} to="/notification">
          <Notification />
          Push notifications
        </NavLink>
        <NavLink activeClassName={style.active} to="/horoscope">
          <Notification />
          Horoscope settings
        </NavLink>
        <NavLink activeClassName={style.active} to="/mantras">
          <CreatePost />
          Mantras
        </NavLink>
      </div>
      <div className={style.leftPanel__logOut}>
        <img src={icons.appLogo} alt="Default avatar" />
        <div>
          <div className={style.leftPanel__logOutName}>Admin</div>
          <div className={style.leftPanel__logOutSubtitle} onClick={logOut}>
            Log out
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftPanel;
