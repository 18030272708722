import React, { useState } from 'react';
import Modal from 'react-modal';
import { customStyles } from './styles';

Modal.setAppElement('#root');

const AppModalWindow = ({ isOpen, onRequestClose, initialPrompt, children }) => {
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [prompt, setPrompt] = useState(initialPrompt || '');

  const handleSave = () => {
    console.log({ date, time, prompt });
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Edit Prompt"
    >
      {children}
    </Modal>
  );
};

export default AppModalWindow;
