import React from 'react';
import style from './styles.module.scss';
import clsx from 'clsx';

import { Check } from '../../assets/svg';

const Checkbox = ({ value, onClick, isDisabled }) => {
  return (
    <div
      className={clsx(style.checkbox, { [style.active]: value })}
      onClick={!isDisabled && onClick}
    >
      {value && <Check />}
    </div>
  );
};

export default Checkbox;
