import React, { useEffect, useState } from "react";
import style from "./styles.module.scss";
import CentralBlock from "../../blocks/notification/centralBlock";
import RightBlock from "../../blocks/notification/rightBlock";
import { fetchRequest } from "../../API/fetchRequest";
const Notification = () => {
  const [notifications, setNotifications] = useState([]);

  const getNotification = async () => {
    let resp = await fetchRequest("GET", "admin/notification", undefined, true);
    console.log("RESP", resp);
    if (resp.success) {
      setNotifications(resp.notifications);
    }
  };

  useEffect(() => {
    getNotification();
  }, []);

  return (
    <div className={style.notificationBlock}>
      <CentralBlock updateNotification={getNotification} />
      <RightBlock notifications={notifications} />
    </div>
  );
};

export default Notification;
