import React, { useState, useEffect } from "react";
import style from "./styles.module.scss";
import ReactAudioPlayer from "react-audio-player";
import Dropzone from "react-dropzone";
import clsx from "clsx";
const DropZone = ({
  setFile,
  file,
  removeFile,
  isMP3,
  error,
  label,
  formik,
}) => {
  const [isDragEnter, setIsDragEnter] = useState(false);
  const [duration, setDuration] = useState(false);

  const handleLoadMetadata = (meta) => {
    const { duration } = meta.target;
    if (formik.values.duration !== duration) {
      formik.setFieldValue("duration", duration);
    }
  };

  // useEffect(()=>{},[])

  if (!!file) {
    let objectURL = null;

    if (typeof file === "string") {
      objectURL = file;
    } else {
      objectURL = URL.createObjectURL(file);
    }

    if (isMP3) {
      return (
        <div className={style.dropZone__mp3Preview}>
          <ReactAudioPlayer
            id="player"
            className={style.dropZone__mp3PreviewTest}
            src={objectURL}
            controls
            onLoadedMetadata={handleLoadMetadata}
          />
        </div>
      );
    } else {
      return (
        <div onClick={removeFile} className={style.dropZone__imgPreview}>
          {/* <div className={style.dropZone__imgPreviewClose} onClick={removeFile}>
            <Close />
          </div> */}
          <img src={objectURL} alt="Choosed img" />
        </div>
      );
    }
  }

  return (
    <Dropzone
      onDrop={(file) => setFile(file)}
      onDragEnter={() => setIsDragEnter(true)}
      onDragLeave={() => setIsDragEnter(false)}
    >
      {({ getRootProps, getInputProps }) => {
        if (isMP3) {
          return (
            <div
              {...getRootProps()}
              className={clsx(style.dropZone__mp3, {
                [style.active]: isDragEnter,
              })}
            >
              <input {...getInputProps()} />
              <div className={style.dropZone__mp3Text}>
                {isDragEnter ? "Put file here" : "Download mp3"}
              </div>
              {!!error && (
                <div className={style.dropZone__mp3Error}>{error}</div>
              )}
            </div>
          );
        } else {
          return (
            <div className={clsx(style.dropZone__wrapper)}>
              {!!label && (
                <div className={clsx(style.dropZone__label)}>{label}</div>
              )}
              <div
                {...getRootProps()}
                className={clsx(style.dropZone, {
                  [style.active]: isDragEnter,
                })}
              >
                <input
                  {...getInputProps()}
                  className={style.imageLoader__input}
                />
                <div className={style.dropZone__text}>
                  {isDragEnter ? "Put file here" : "Click to upload an image"}
                </div>
                {!!error && (
                  <div className={style.dropZone__error}>{error}</div>
                )}
              </div>
            </div>
          );
        }
      }}
    </Dropzone>
  );
};

export default DropZone;
