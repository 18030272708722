import React, { useEffect, useState } from "react";
import style from "./styles.module.scss";
import { icons } from "../../assets/png";
import { createPortal } from "react-dom";
import { Loader } from "..";
const SuccessModal = ({ text, onClose }) => {
  const [showModal, setShowModal] = useState(true);
  useEffect(() => {
    document.getElementById("root").classList.add("blur");
    return () => document.getElementById("root").classList.remove("blur");
  }, []);
  return createPortal(
    <div className={style.modalBlock}>
      {showModal ? (
        <div>
          <div className={style.modalBlock__topOverlay}>
            <img src={icons.modalLogo} alt="Modal logo" />
            <div className={style.modalBlock__text}>{text}</div>
            <div className={style.modalBlock__buttonsBlock}>
              <div className={style.modalBlock__leftButton} onClick={onClose}>
                OK
              </div>
            </div>
          </div>

          <div className={style.modalBlock__bottomOverlay} />
        </div>
      ) : (
        <Loader />
      )}
    </div>,

    document.body
  );
};

export default SuccessModal;
