import * as Yup from "yup";
export const CreatePostShema = Yup.object().shape({
  page: Yup.string().required("Page is required"),
  category: Yup.string().when("type", {
    is: "Story",
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required("Category is required"),
  }),
  type: Yup.string().required("Type is required"),
  cover: Yup.mixed().nullable("Error").required("Cover is required"),
  title: Yup.string().max(16, "Title must be at most 16 characters"),

  audio: Yup.mixed()
    .nullable("Error")
    .when(
      "type",
      {
        is: "Music",
        then: Yup.mixed().nullable("Error").required("Audio is required"),
      },
      ["audio", "type"]
    ),
  time: Yup.string().min(5, "Invalid time").notRequired(),
});
