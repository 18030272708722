import React from 'react';

const HorizontalDotsIcon = ({ width = '16', height = '16', fill = 'none', stroke = '#000000' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.6665 7.99992C2.6665 8.17673 2.73674 8.3463 2.86177 8.47132C2.98679 8.59635 3.15636 8.66659 3.33317 8.66659C3.50998 8.66659 3.67955 8.59635 3.80457 8.47132C3.9296 8.3463 3.99984 8.17673 3.99984 7.99992C3.99984 7.82311 3.9296 7.65354 3.80457 7.52851C3.67955 7.40349 3.50998 7.33325 3.33317 7.33325C3.15636 7.33325 2.98679 7.40349 2.86177 7.52851C2.73674 7.65354 2.6665 7.82311 2.6665 7.99992Z"
        stroke={stroke}
        strokeOpacity="0.6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.33317 7.99992C7.33317 8.17673 7.40341 8.3463 7.52843 8.47132C7.65346 8.59635 7.82303 8.66659 7.99984 8.66659C8.17665 8.66659 8.34622 8.59635 8.47124 8.47132C8.59627 8.3463 8.6665 8.17673 8.6665 7.99992C8.6665 7.82311 8.59627 7.65354 8.47124 7.52851C8.34622 7.40349 8.17665 7.33325 7.99984 7.33325C7.82303 7.33325 7.65346 7.40349 7.52843 7.52851C7.40341 7.65354 7.33317 7.82311 7.33317 7.99992Z"
        stroke={stroke}
        strokeOpacity="0.6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9998 7.99992C11.9998 8.17673 12.0701 8.3463 12.1951 8.47132C12.3201 8.59635 12.4897 8.66659 12.6665 8.66659C12.8433 8.66659 13.0129 8.59635 13.1379 8.47132C13.2629 8.3463 13.3332 8.17673 13.3332 7.99992C13.3332 7.82311 13.2629 7.65354 13.1379 7.52851C13.0129 7.40349 12.8433 7.33325 12.6665 7.33325C12.4897 7.33325 12.3201 7.40349 12.1951 7.52851C12.0701 7.65354 11.9998 7.82311 11.9998 7.99992Z"
        stroke={stroke}
        strokeOpacity="0.6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HorizontalDotsIcon;
